import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import './index.css';
import DashboardHeader from './DashboardHeader';

import { 
  ArrowDownTrayIcon, 
  ArrowUpTrayIcon, 
  DocumentIcon, 
  ExclamationCircleIcon,
  CheckCircleIcon,
  CpuChipIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  XMarkIcon,
  PencilSquareIcon,
  TrashIcon,
  SparklesIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/24/outline';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

// Aggiungi questi stili globali nel tuo CSS
const globalStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.5);
    border-radius: 3px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(156, 163, 175, 0.7);
  }
`;

// Aggiungi all'inizio del file, dopo l'import di axios
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Gestisci il token scaduto
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Componente TableCell migliorato
const TableCell = ({ content, isEditing, onChange, isDarkMode, type, index, onSave, onCancel }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [localContent, setLocalContent] = useState(content || '');
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    setLocalContent(content || '');
  }, [content]);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 128); // 8rem = 128px
    }
  }, [content]);

  if (isEditing) {
    return (
      <div className="relative">
        <textarea
          value={localContent}
          onChange={(e) => {
            setLocalContent(e.target.value);
            onChange(index, type, e.target.value);
          }}
          className={`w-full p-2 rounded border ${
            isDarkMode 
              ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
              : 'bg-white border-gray-300 text-gray-900'
          } focus:ring-2 focus:ring-blue-500`}
          rows={3}
          placeholder={`Inserisci ${type === 'domanda' ? 'la domanda' : 'la risposta'}...`}
        />
        <div className="absolute bottom-2 right-2 text-xs text-gray-400">
          {localContent.length} caratteri
        </div>
        
        {/* Bottoni Salva e Annulla */}
        <div className="mt-2 flex justify-end space-x-2">
          <button
            onClick={() => onCancel(index)}
            className={`px-3 py-1 rounded-md text-sm ${
              isDarkMode
                ? 'bg-gray-700 text-white hover:bg-gray-600'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            Annulla
          </button>
          <button
            onClick={() => onSave(index, type, localContent)}
            className={`px-3 py-1 rounded-md text-sm text-white ${
              isDarkMode
                ? 'bg-green-600 hover:bg-green-700'
                : 'bg-green-500 hover:bg-green-600'
            }`}
          >
            Salva
          </button>
        </div>
      </div>
    );
  }

  return (
    <td className="px-6 py-4 text-sm text-gray-900 dark:text-gray-100 max-w-md relative">
      <div
        ref={contentRef}
        className={`whitespace-pre-wrap break-words ${
          isExpanded ? 'max-h-none' : 'max-h-32'
        } overflow-hidden transition-all duration-200`}
      >
        {content}
      </div>
      {isOverflowing && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 text-xs mt-2 focus:outline-none"
        >
          {isExpanded ? 'Mostra meno' : 'Mostra tutto'}
        </button>
      )}
    </td>
  );
};

// Menu Azioni migliorato
const ActionMenu = ({ onEdit, onDelete, onAIFix, onCancel, isDarkMode, position = 'bottom' }) => (
  <div className={`absolute z-50 w-48 rounded-md shadow-lg ${
    position === 'top' 
      ? 'bottom-full right-0 mb-2' // Menu sopra il bottone
      : position === 'bottom-shifted'
        ? 'top-0 left-0 ml-[-200px]' // Menu a sinistra con offset fisso
        : 'top-0 right-0 mt-2'    // Menu sotto il bottone (default)
  } ${
    isDarkMode ? 'bg-gray-800 border border-gray-700' : 'bg-white border border-gray-200'
  }`}>
    <div className="py-1">
      <button
        onClick={onEdit}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-gray-200 hover:bg-gray-700' 
            : 'text-gray-700 hover:bg-gray-50'
        }`}
      >
        <PencilSquareIcon className="h-4 w-4 mr-2" />
        Modifica
      </button>
      
      <button
        onClick={onAIFix}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-purple-300 hover:bg-gray-700' 
            : 'text-purple-600 hover:bg-gray-50'
        }`}
      >
        <SparklesIcon className="h-4 w-4 mr-2" />
        Fix AI
      </button>
      
      <button
        onClick={onDelete}
        className={`flex items-center w-full px-4 py-2 text-sm ${
          isDarkMode 
            ? 'text-red-300 hover:bg-gray-700' 
            : 'text-red-600 hover:bg-gray-50'
        }`}
      >
        <TrashIcon className="h-4 w-4 mr-2" />
        Elimina
      </button>

      <button
        onClick={onCancel}
        className={`flex items-center w-full px-4 py-2 text-sm border-t ${
          isDarkMode 
            ? 'text-gray-300 hover:bg-gray-700 border-gray-700' 
            : 'text-gray-500 hover:bg-gray-50 border-gray-200'
        }`}
      >
        <XMarkIcon className="h-4 w-4 mr-2" />
        Chiudi
      </button>
    </div>
  </div>
);

// Componente Pagination
const Pagination = ({ currentPage, totalPages, onPageChange, rowsPerPage, onRowsPerPageChange, isDarkMode }) => (
  <div className="mt-4 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 pt-4">
    <div className="flex items-center">
      <select
        value={rowsPerPage}
        onChange={(e) => onRowsPerPageChange(Number(e.target.value))}
        className={`rounded-md border ${
          isDarkMode 
            ? 'bg-gray-700 border-gray-600 text-white' 
            : 'bg-white border-gray-300 text-gray-900'
        } py-1 pl-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
      >
        {[10, 25, 50, 100].map(value => (
          <option key={value} value={value}>
            {value} righe
          </option>
        ))}
      </select>
    </div>

    <div className="flex items-center space-x-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-3 py-1 rounded-md ${
          isDarkMode 
            ? 'bg-gray-700 text-white disabled:text-gray-500' 
            : 'bg-white text-gray-700 disabled:text-gray-400'
        } border ${
          isDarkMode ? 'border-gray-600' : 'border-gray-300'
        } disabled:opacity-50`}
      >
        Precedente
      </button>

      <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
        Pagina {currentPage} di {totalPages}
      </span>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 rounded-md ${
          isDarkMode 
            ? 'bg-gray-700 text-white disabled:text-gray-500' 
            : 'bg-white text-gray-700 disabled:text-gray-400'
        } border ${
          isDarkMode ? 'border-gray-600' : 'border-gray-300'
        } disabled:opacity-50`}
      >
        Successiva
      </button>
    </div>
  </div>
);

// Nuovo componente StatusBar
const StatusBar = ({ 
  hasErrors, 
  errorCount, 
  onShowErrorsOnly, 
  onShowAll,
  showErrorsOnly,
  onProceed,
  onSendForFinetuning,
  jsonFilePath,
  isDarkMode,
  isProcessing
}) => (
  <div className={`fixed bottom-0 left-0 right-0 z-40 p-4 shadow-md ${
    isDarkMode ? 'bg-gray-800 border-t border-gray-700' : 'bg-white border-t border-gray-200'
  }`}>
    <div className="max-w-7xl mx-auto flex items-center justify-between">
      <div className={`flex items-center ${
        isDarkMode ? 'text-white' : 'text-gray-900'
      }`}>
        <span className="font-medium">
          {hasErrors 
            ? `Dataset Status: ${errorCount} ${errorCount === 1 ? 'errore' : 'errori'} da correggere`
            : 'Dataset pronto per la conversione'}
        </span>
      </div>
      
      <div className="flex space-x-4">
        {/* Mostra sempre il bottone "Mostra Tutti" se siamo in modalità filtrata */}
        {showErrorsOnly && (
          <button
            onClick={onShowAll}
            className={`px-4 py-2 rounded-md text-white ${
              isDarkMode 
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            Mostra Tutti
          </button>
        )}

        {/* Mostra i bottoni appropriati in base allo stato */}
        {hasErrors ? (
          !showErrorsOnly && (
            <button
              onClick={onShowErrorsOnly}
              className={`px-4 py-2 rounded-md text-white ${
                isDarkMode 
                  ? 'bg-yellow-600 hover:bg-yellow-700' 
                  : 'bg-yellow-500 hover:bg-yellow-600'
              }`}
            >
              Correggi File
            </button>
          )
        ) : (
          <div className="flex space-x-2">
            {jsonFilePath ? (
              <button
                onClick={() => onSendForFinetuning(jsonFilePath)}
                disabled={isProcessing}
                className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                  isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                <CpuChipIcon className="mr-2 h-4 w-4" />
                Invia per Fine-Tuning
              </button>
            ) : (
              <button
                onClick={onProceed}
                disabled={isProcessing}
                className={`px-4 py-2 rounded-md text-white ${
                  isProcessing
                    ? 'bg-gray-400 cursor-not-allowed'
                    : isDarkMode
                      ? 'bg-green-600 hover:bg-green-700'
                      : 'bg-green-500 hover:bg-green-600'
                }`}
              >
                {isProcessing ? 'Elaborazione...' : 'Procedi con conversione JSONL'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

const Dashboard = ({ onLogout, isDarkMode }) => {
  // State variables for managing file upload and processing
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [purpose] = useState('assistenza_clienti_primo_livello');
  const [templateFormat, setTemplateFormat] = useState('csv');
  const [messages, setMessages] = useState([]);
  const [jsonFilePath, setJsonFilePath] = useState(null);
  const [jsonPreview, setJsonPreview] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewData, setPreviewData] = useState([]);
  
  // State variables for dataset expansion
  const [useExpansion, setUseExpansion] = useState(true);
  const [numVariants, setNumVariants] = useState(10);
  
  
  // State variables for fine-tuning process
  const [fineTuneStatus, setFineTuneStatus] = useState(null);
  const [isFineTuning, setIsFineTuning] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
    
  // Aggiungi questi nuovi stati
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState(null);
    
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showActions, setShowActions] = useState(null);
  const [activeRow, setActiveRow] = useState(null);

  // Aggiungi questo stato
  const [menuPosition, setMenuPosition] = useState('bottom');

  // Aggiungi questi nuovi stati
  const [showErrorsOnly, setShowErrorsOnly] = useState(false);
  
  const [stats, setStats] = useState({
    total: 0,
    valid: 0,
    invalid: 0
  });
  
  // Calcola i dati paginati
  const paginatedData = React.useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const dataToUse = isEditMode ? editedData : previewData;
    return dataToUse.slice(startIndex, startIndex + rowsPerPage);
  }, [currentPage, rowsPerPage, previewData, editedData, isEditMode]);

  // Controlla che il valore inserito dall'utente sia almeno 10
  const handleNumVariantsChange = (e) => {
  const value = parseInt(e.target.value);
  setNumVariants(value >= 10 ? value : 10); // Imposta 10 come minimo
  };    
  // Callback function for appending messages
  const appendMessage = useCallback((message, isUser = false) => {
    setMessages(prevMessages => [...prevMessages, { content: message, isUser }]);
  }, []);

  // Effect for tracking elapsed time during fine-tuning
  useEffect(() => {
    let timer;
    if (isFineTuning && startTime) {
      timer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
        setElapsedTime(elapsedSeconds);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isFineTuning, startTime]);

  // Helper function to format elapsed time
  const formatElapsedTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

 
  // Template download handler
  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }
    
    try {
      const response = await axios.get(`${API_BASE_URL}/download_template/${purpose}/${templateFormat}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const fileExtension = templateFormat === 'word' ? 'docx' : templateFormat;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${purpose}_template.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      appendMessage('Template scaricato con successo. Puoi usarlo come riferimento per preparare il tuo file.', false);
    } catch (error) {
      console.error('Errore durante il download del template:', error);
      appendMessage('Errore durante il download del template. Riprova più tardi.', false);
    }
  };

  // File selection handler
  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  // File upload and preview handler
  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file da caricare.', false);
      return;
    }

    setIsProcessing(true);
    setProgress(0);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('purpose', purpose);
    formData.append('use_expansion', useExpansion);
    formData.append('num_variants', numVariants);

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }

    try {
      // Determina l'endpoint in base al numero di file
      const endpoint = selectedFiles.length > 1 ? 
        `${API_BASE_URL}/merge/upload` : 
        `${API_BASE_URL}/upload_preview`;

      appendMessage(
        <div className="flex items-center">
          <span>
            {selectedFiles.length > 1 ? 
              'Analisi e merge dei documenti in corso...' : 
              'Analisi del documento in corso...'}
          </span>
        </div>,
        false
      );

      const response = await axios.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      setIsProcessing(false);
      setProgress(100);

      if (selectedFiles.length > 1) {
        // Gestione risposta merge
        if (response.data.preview) {
          // Gestione preview per il merge come nel caso del singolo file
          const previewData = response.data.preview.data || [];
          const previewWithStatus = previewData.map(row => ({
            ...row,
            status: row.status || (row.risposta && row.risposta.length > 500 ? 'invalid' : 'valid')
          }));
          
          // Salva i dati sia in previewData che in una variabile di backup
          setPreviewData(previewWithStatus);
          window._lastPreviewData = previewWithStatus;
          
          // Aggiorna anche le statistiche
          setStats({
            total: response.data.total_records,
            valid: response.data.preview.stats.valid_records,
            invalid: response.data.preview.stats.invalid_records
          });

          appendMessage(
            <div className="flex items-center">
              <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
              <span>
                Merge completato con successo. Record totali: {response.data.total_records}
              </span>
            </div>,
            false
          );

          // Mostra statistiche e problemi di validazione
          if (response.data.quality_score) {
            appendMessage(
              <div className="flex items-center">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span>
                  Qualità del dataset: {Math.round(response.data.quality_score * 100)}%
                </span>
              </div>,
              false
            );
          }

          if (response.data.problems) {
            appendMessage(
              <div className="flex items-center">
                <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-2" />
                <span>Alcuni file hanno presentato problemi:</span>
              </div>,
              false
            );
            response.data.problems.forEach(problem => {
              appendMessage(
                <div className="ml-5">
                  - {problem.filename}: {problem.error}
                </div>,
                false
              );
            });
          }

          // Mostra suggerimenti di miglioramento se presenti
          if (response.data.improvement_suggestions?.length > 0) {
            appendMessage(
              <div className="flex items-center">
                <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span>
                  Suggerimenti di miglioramento: {response.data.improvement_suggestions.join(', ')}
                </span>
              </div>,
              false
            );
          }

          // Se non ci sono problemi critici, mostra il pulsante per procedere
          if (!response.data.critical_problems?.length) {
            appendMessage(
              <button
                onClick={handleGenerateJson}
                className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                  isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4`}
              >
                Procedi
              </button>,
              false
            );
          }
        }
      } else {
        // Gestione risposta singolo file (codice esistente)
        if (response.data.results) {
          response.data.results.forEach((result) => {
            if (result.error) {
              appendMessage(
                <div className="flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                  <span>Errore nel file {result.filename}: {result.error}</span>
                </div>,
                false
              );
            } else {
              // Messaggio di successo senza barra
              appendMessage(
                <div className="flex items-center">
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                  <span>File elaborato con successo: {result.filename}</span>
                </div>,
                false
              );

              if (result.preview) {
                const previewWithStatus = result.preview.map(row => ({
                  ...row,
                  status: row.risposta && row.risposta.length > 500 ? 'invalid' : 'valid'
                }));
                
                // Salva i dati sia in previewData che in una variabile di backup
                setPreviewData(previewWithStatus);
                window._lastPreviewData = previewWithStatus; // Manteniamo il backup dei dati
                
                console.log('PreviewData impostato con status:', previewWithStatus);
              }

              if (result.problems && result.problems.length > 0) {
                appendMessage(
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-2" />
                    <span>Problemi rilevati: {result.problems.join(', ')}</span>
                  </div>,
                  false
                );
              }

              if (result.improvement_suggestions && result.improvement_suggestions.length > 0) {
                appendMessage(
                  <div className="flex items-center">
                    <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />
                    <span>Suggerimenti di miglioramento: {result.improvement_suggestions.join(', ')}</span>
                  </div>,
                  false
                );
              }

              if (result.removed_rows && result.removed_rows.length > 0) {
                appendMessage(
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-2" />
                    <span>
                      Attenzione: Sono state rimosse {result.removed_rows.length} righe per dati incompleti.
                      <br />
                      Dettagli delle righe rimosse: {result.removed_rows.join(', ')}
                    </span>
                  </div>,
                  false
                );
              }

              if (result.critical_problems && result.critical_problems.length > 0) {
                appendMessage(
                  <div>
                    <div className="flex items-center">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                      <span>Sono stati rilevati problemi critici: {result.critical_problems.join(', ')}</span>
                    </div>
                    <div className="mt-2 space-x-2">
                      <button 
                        onClick={() => handleCorrectFile(result.filename)}
                        className={`px-4 py-2 text-white rounded transition-colors duration-200 ${
                          isDarkMode ? 'bg-yellow-600 hover:bg-yellow-700' : 'bg-yellow-500 hover:bg-yellow-600'
                        }`}
                      >
                        Correggi File
                      </button>
                      <button 
                        onClick={() => handleProceedAnyway(result.filename)}
                        className={`px-4 py-2 text-white rounded transition-colors duration-200 ${
                          isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
                        }`}
                      >
                        Procedi comunque
                      </button>
                    </div>
                  </div>,
                  false
                );
              } else {
                appendMessage(
                  <div className="flex items-center">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                    <span>Il file è valido e pronto per il fine-tuning.</span>
                  </div>,
                  false
                );
                appendMessage(
                  <button
                    onClick={handleGenerateJson}
                    className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                      isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4`}
                  >
                    Procedi
                  </button>,
                  false
                );
              }
            }
          });
        }
      }
    } catch (error) {
      setIsProcessing(false);
      setProgress(0);
      appendMessage(
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
          <span>Errore durante il caricamento: {error.response?.data?.detail || error.message}</span>
        </div>,
        false
      );
    }
  };

  // Modifica la funzione handleDeleteRecord
  const handleDeleteRecord = (index) => {
    setEditedData(prevData => {
      // Se editedData non esiste, inizializzalo da previewData
      const currentData = prevData || [...previewData];
      const newData = [...currentData];
      newData.splice(index, 1);
      return newData;
    });

    // Aggiorna anche previewData per mantenere la sincronizzazione
    setPreviewData(prevData => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });

    appendMessage(
      <div className="flex items-center">
        <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
        <span>Record eliminato con successo</span>
      </div>,
      false
    );
  };

  // File correction handler
  const handleCorrectFile = (filename) => {
    console.log('1. handleCorrectFile chiamato con:', { filename });
    
    const dataToUse = previewData.length > 0 ? previewData : window._lastPreviewData;
    
    console.log('2. Dati disponibili:', {
      previewDataLength: previewData.length,
      backupDataLength: window._lastPreviewData?.length,
      usingBackup: previewData.length === 0
    });

    if (!dataToUse || dataToUse.length === 0) {
      console.log('3. Nessun dato disponibile');
      appendMessage("Nessun dato da modificare", false);
      return;
    }

    // Crea una copia esatta dei dati esistenti
    const initialEditData = dataToUse.map((row, index) => ({
      ...row,
      status: row.risposta && row.risposta.length > 500 ? 'invalid' : 'valid',
      validationMessage: row.risposta && row.risposta.length > 500 
        ? "La risposta supera i 500 caratteri" 
        : ""
    }));

    console.log('5. Dati pronti per la modifica:', initialEditData);
    
    setEditedData(initialEditData);
    setIsEditMode(true);
    
    appendMessage(
      <div>
        <p>Modalità modifica attivata. Puoi modificare i dati direttamente nelle celle o eliminare i record problematici.</p>
        <ul className="list-disc list-inside mt-2 text-sm">
          <li>Le celle rosse indicano contenuti che necessitano correzioni</li>
          <li>Usa il pulsante "Elimina" per rimuovere un record problematico</li>
          <li>Passa il mouse sopra le celle per vedere i dettagli degli errori</li>
          <li>Il contatore di caratteri ti aiuterà a rispettare i limiti</li>
        </ul>
      </div>, 
      false
    );
  };

  // Modifica l'effetto per monitorare più dettagli
  useEffect(() => {
    console.log('Stato aggiornato:', {
      isEditMode,
      editedDataLength: editedData?.length,
      previewDataLength: previewData?.length,
      previewDataType: typeof previewData,
      isPreviewArray: Array.isArray(previewData),
      editedDataType: typeof editedData,
      isEditedArray: Array.isArray(editedData)
    });
  }, [isEditMode, editedData, previewData]);

  // Handler for proceeding despite errors
  const handleProceedAnyway = async (filename) => {
    appendMessage(`Hai scelto di procedere con il fine-tuning per il file ${filename} nonostante i problemi rilevati. Generazione del JSON in corso...`, false);
    try {
      await handleGenerateJson(true);
    } catch (error) {
      appendMessage('Errore durante la generazione del JSON. Verifica i dati e riprova.', false);
      console.error('Errore durante handleProceedAnyway:', error);
    }
  };

  // JSON generation handler
  const handleGenerateJson = async (fromProceedAnyway = false) => {
    if (selectedFiles.length === 0) {
      appendMessage('Per favore, seleziona almeno un file da processare.', false);
      return;
    }

    setIsProcessing(true);
    setProgress(0);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('purpose', purpose);
    formData.append('use_expansion', useExpansion);
    if (useExpansion) {
      formData.append('num_variants', numVariants);
    }

    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setIsProcessing(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/convert_to_json`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.task_id) {
        appendMessage(`Processo di conversione avviato. ID del task: ${response.data.task_id}`, false);
        await pollTaskStatus(response.data.task_id);
      } else {
        appendMessage('Errore: Il processo di conversione non è stato avviato correttamente.', false);
      }
    } catch (error) {
      appendMessage('Errore durante la generazione del file JSON. Riprova più tardi.', false);
    } finally {
      setIsProcessing(false);
    }
  };
  // Task status polling
  const pollTaskStatus = async (taskId) => {
  const maxAttempts = 180; // Aumentato a 180 tentativi (15 minuti con intervallo di 5 secondi)
  const interval = 5000; // 5 secondi tra ogni tentativo
  let lastProgress = 0;
  let lastMessageTime = Date.now();
  
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 30000 // 30 secondi di timeout per ogni richiesta
      });

      if (response.data.state === 'PROCESSING') {
        // Calcola il progresso in modo più graduale
        const currentProgress = Math.min(80, 20 + (attempt / maxAttempts) * 60);
        if (currentProgress > lastProgress) {
          setProgress(currentProgress);
          lastProgress = currentProgress;
        }

        // Mostra messaggi di stato ogni 30 secondi per evitare spam
        const currentTime = Date.now();
        if (currentTime - lastMessageTime > 30000) {
          appendMessage(
            `Elaborazione in corso: ${response.data.status || 'Generazione del dataset in corso...'}` +
            (useExpansion ? ' Questo processo potrebbe richiedere alcuni minuti.' : ''),
            false
          );
          lastMessageTime = currentTime;
        }
      } else if (response.data.state === 'SUCCESS') {
        setProgress(100);
        appendMessage(`Processo completato con successo. File JSON generato.`, false);
        
        if (response.data.json_file_path) {
          setJsonFilePath(response.data.json_file_path);
          appendMessage(`File JSON pronto per il fine-tuning: ${response.data.json_file_path}`, false);

          if (response.data.json_preview && response.data.json_preview.length > 0) {
            setJsonPreview(response.data.json_preview);
          }

          appendMessage(
            <button
              onClick={() => handleSendForFinetuning(response.data.json_file_path)}
              className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4`}
            >
              <CpuChipIcon className="mr-2 h-4 w-4" />
              Invia per Fine-Tuning
            </button>,
            false
          );
        }
        return;
      } else if (response.data.state === 'FAILURE') {
        setProgress(0);
        appendMessage(`Errore durante la generazione del JSON: ${response.data.status}`, false);
        return;
      }

      await new Promise(resolve => setTimeout(resolve, interval));
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // Timeout della singola richiesta, continua con il polling
        console.warn('Timeout della richiesta, ritento...');
        if (attempt % 3 === 0) { // Mostra messaggio ogni 3 tentativi
          appendMessage(
            'Il processo è ancora in corso. Continuo a monitorare lo stato...',
            false
          );
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        continue;
      }

      // Verifica se è un errore di rete
      if (!navigator.onLine) {
        appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
        // Attendi che la connessione torni prima di continuare
        await new Promise(resolve => {
          const checkConnection = () => {
            if (navigator.onLine) {
              appendMessage('Connessione ripristinata. Riprendo il monitoraggio...', false);
              resolve();
            } else {
              setTimeout(checkConnection, 1000);
            }
          };
          checkConnection();
        });
        continue;
      }

      // Per altri tipi di errori, continua il polling
      console.error('Errore durante il polling:', error);
      if (attempt % 3 === 0) { // Limita i messaggi di errore
        appendMessage(
          'Si è verificato un errore temporaneo. Continuo a monitorare il processo...',
          false
        );
      }
      await new Promise(resolve => setTimeout(resolve, interval));
      continue;
    }
  }

  // Se raggiungiamo il numero massimo di tentativi
  appendMessage(
    'Il processo sta richiedendo più tempo del previsto. ' +
    'Continuerà in background e riceverai una notifica quando sarà completato. ' +
    'Non chiudere questa finestra.',
    false
  );
  setProgress(95); // Mantiene il progresso alto per indicare che è ancora in corso
};

  const handleSendForFinetuning = async (jsonFilePathParam) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }

    const formData = new FormData();
    formData.append('json_file_path', jsonFilePathParam);
    formData.append('purpose', purpose);

    const response = await axios.post(`${API_BASE_URL}/process_finetuning`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60000
    });

    if (response.data.temporary_job_id) {
      const temporaryJobId = response.data.temporary_job_id;
      appendMessage(
        <div>
          <p>Fine-tuning avviato con successo!</p>
          <p className="mt-2 text-sm opacity-75">ID Temporaneo: {temporaryJobId}</p>
          <p className="mt-2">
            Il processo completo potrebbe richiedere da 15 a 30 minuti. 
            Monitorerai il progresso in questa finestra.
          </p>
        </div>,
        false
      );
      setProgress(5);
      pollRealJobId(temporaryJobId);
      return;
    } else {
      throw new Error('ID temporaneo del job non ricevuto dal server');
    }
  } catch (error) {
    if (error.response?.status === 401) {
      appendMessage('Sessione scaduta. Effettua nuovamente il login.', false);
      setIsFineTuning(false);
      return;
    }
    const errorMessage = error.response?.data?.detail || error.message;
    appendMessage(
      <div>
        <p className="text-red-500">Errore durante l'avvio del fine-tuning:</p>
        <p className="mt-2">{errorMessage}</p>
        <p className="mt-2">
          Suggerimenti:
          <ul className="list-disc ml-4 mt-1">
            <li>Verifica la tua connessione internet</li>
            <li>Controlla che il file JSON sia valido</li>
            <li>Riprova tra qualche minuto</li>
          </ul>
        </p>
      </div>,
      false
    );
    setProgress(0);
    setIsFineTuning(false);
  }
};

  const pollRealJobId = async (temporaryJobId) => {
  const maxRetries = 60; // Aumentato a 60 tentativi (5 minuti con intervallo di 5 secondi)
  const interval = 5000;
  let attempts = 0;
  let lastMessageTime = Date.now();

  const pollInterval = setInterval(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get_real_job_id/${temporaryJobId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        timeout: 30000 // 30 secondi di timeout per ogni richiesta
      });

      if (response.data.real_job_id) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p>✓ Job ID OpenAI ottenuto con successo</p>
            <p className="text-sm opacity-75 mt-1">ID: {response.data.real_job_id}</p>
            <p className="mt-2">Avvio monitoraggio del processo di fine-tuning...</p>
          </div>,
          false
        );
        setProgress(50);
        monitorFineTuneStatus(response.data.real_job_id);
        return;
      } else if (response.data.status === 'processing') {
        // Aggiorna il progresso in modo più graduale
        setProgress(Math.min(45, 5 + (attempts / maxRetries) * 40));
        
        // Mostra messaggi di stato meno frequentemente
        const currentTime = Date.now();
        if (currentTime - lastMessageTime > 30000) { // Ogni 30 secondi
          appendMessage('Creazione del job di fine-tuning in corso...', false);
          lastMessageTime = currentTime;
        }
      } else {
        // Per altri stati di risposta inattesi
        console.warn('Stato inatteso ricevuto:', response.data);
      }

      attempts++;
      if (attempts >= maxRetries) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p>Il processo sta richiedendo più tempo del previsto.</p>
            <p className="mt-2">Il fine-tuning continuerà in background e riceverai una notifica al completamento.</p>
            <p className="mt-2">Puoi:</p>
            <ul className="list-disc ml-4 mt-1">
              <li>Continuare a monitorare questa pagina</li>
              <li>Controllare lo stato più tardi dal dashboard</li>
              <li>Attendere la notifica di completamento</li>
            </ul>
          </div>,
          false
        );
        setProgress(95);
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // Timeout della singola richiesta, continua
        console.warn('Timeout della richiesta, ritento...');
        return;
      }

      if (!navigator.onLine) {
        appendMessage('Connessione di rete persa. In attesa di riconnessione...', false);
        
        // Attendi che la connessione torni
        const checkConnection = () => {
          if (navigator.onLine) {
            appendMessage('Connessione ripristinata. Riprendo il monitoraggio...', false);
            return;
          }
          setTimeout(checkConnection, 1000);
        };
        checkConnection();
        return;
      }

      const errorMessage = error.response?.data?.detail || error.message;
      console.error('Errore durante il polling:', error);

      // Per errori gravi, interrompi il polling
      if (error.response?.status === 404 || error.response?.status === 401) {
        clearInterval(pollInterval);
        appendMessage(
          <div>
            <p className="text-red-500">Errore durante il monitoraggio del job:</p>
            <p className="mt-2">{errorMessage}</p>
            <p className="mt-2">Si prega di riprovare più tardi o contattare il supporto.</p>
          </div>,
          false
        );
        setProgress(0);
        setIsFineTuning(false);
      } else {
        // Per altri errori, mostra il messaggio ma continua il polling
        appendMessage(
          'Si è verificato un errore temporaneo. Continuo a monitorare il processo...',
          false
        );
      }
    }
  }, interval);

  // Cleanup function
  return () => {
    clearInterval(pollInterval);
  };
};




// funzione per mostrare gli status del fine-tuning e del tempo trascorso
  const monitorFineTuneStatus = (jobId) => {
  const MAX_RETRIES = 180;  // 30 minuti totali con intervallo di 10 secondi
  const POLLING_INTERVAL = 10000;  // 10 secondi
  let retryCount = 0;
  let lastSuccessfulCheck = Date.now();
  let pollingIntervalId = null;

  const token = localStorage.getItem('token');
  if (!token) {
    appendMessage('Token mancante. Effettua nuovamente il login.', false);
    return;
  }

  const checkStatus = async () => {
    try {
      // Ottieni il token dal localStorage
      const token = localStorage.getItem('token');
      
      if (!token) {
        appendMessage('Token mancante. Effettua nuovamente il login.', false);
        setIsFineTuning(false);
        return;
      }

      console.log("Invio richiesta di stato per il fine-tuning con token:", token.substring(0, 10) + "...");
      
      const response = await axios.get(`${API_BASE_URL}/fine_tune_status`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        timeout: 30000
      });

      console.log("Risposta ricevuta dal server:", response.data);

      if (!response.data || !response.data.jobs) {
        throw new Error('Risposta non valida dal server');
      }

      const job = response.data.jobs.find(j => j.job_id === jobId);

      if (!job) {
        // Se il job non viene trovato, verifica quanto tempo è passato
        const timeSinceLastSuccess = Date.now() - lastSuccessfulCheck;
        if (timeSinceLastSuccess > 300000) { // 5 minuti
          throw new Error('Job non trovato dopo multipli tentativi');
        }
        retryCount++;
        return; // Continua il polling
      }

      // Reset dei contatori perché abbiamo ricevuto una risposta valida
      lastSuccessfulCheck = Date.now();
      retryCount = 0;

      console.log(`Stato del job trovato: ${job.status}`);
      setFineTuneStatus(job.status);
      
      // Gestione stati intermedi aggiuntivi
      const progressMapping = {
        'created': 5,
        'validating_files': 10,
        'queued': 20,
        'preparing': 30,
        'running': prevProgress => Math.min(90, prevProgress + 1),
        'succeeded': 100,
        'failed': 0,
        'cancelled': 0
      };

      if (progressMapping.hasOwnProperty(job.status)) {
        const newProgress = progressMapping[job.status];
        if (typeof newProgress === 'function') {
          setProgress(prevProgress => newProgress(prevProgress));
        } else {
          setProgress(newProgress);
        }
      }

      switch (job.status) {
        case 'succeeded':
          if (job.fine_tuned_model) {
            appendMessage(
              <div className={`transition-colors duration-200`}>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                  Fine-tuning completato con successo!
                </p>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                  Modello fine-tuned:
                </p>
                <pre className={`p-2 rounded mt-2 transition-colors duration-200 ${
                  isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-800'
                }`}>
                  {job.fine_tuned_model}
                </pre>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(job.fine_tuned_model);
                    appendMessage('ID del modello copiato negli appunti!', false);
                  }}
                  className={`mt-2 px-4 py-2 text-white rounded transition-colors duration-200 ${
                    isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'
                  }`}
                >
                  Copia ID Modello
                </button>
              </div>,
              false
            );
            clearInterval(pollingIntervalId);
            setIsFineTuning(false);
            setShowInfoMessage(false);
          }
          break;

        case 'failed':
          appendMessage(
            <div>
              <p className="text-red-500">Il fine-tuning è fallito.</p>
              {job.error && (
                <p className="mt-2 text-sm">Dettaglio errore: {job.error}</p>
              )}
              <p className="mt-2">Si prega di verificare i log e riprovare.</p>
            </div>,
            false
          );
          clearInterval(pollingIntervalId);
          setIsFineTuning(false);
          setShowInfoMessage(false);
          break;

        case 'running':
          appendMessage(
            <div>
              <p>Stato del fine-tuning: {job.status}</p>
              <p className="text-sm mt-1">Job ID: {job.job_id}</p>
              <p className="text-sm mt-1">Tempo trascorso: {formatElapsedTime(elapsedTime)}</p>
            </div>,
            false
          );
          break;

        default:
          appendMessage(`Stato del fine-tuning: ${job.status}. Job ID: ${job.job_id}`, false);
      }

    } catch (error) {
      console.error('Errore durante il monitoraggio:', error);
      retryCount++;

      // Gestione specifica degli errori
      if (error.code === 'ECONNABORTED') {
        appendMessage('Timeout della richiesta. Riprovo...', false);
        return; // Riprova al prossimo intervallo
      }

      if (retryCount >= MAX_RETRIES) {
        clearInterval(pollingIntervalId);
        appendMessage(
          'Troppi errori consecutivi nel monitoraggio. ' +
          'Il processo continua in background. ' +
          'Controlla lo stato più tardi dal dashboard.',
          false
        );
        setIsFineTuning(false);
      } else if (retryCount % 3 === 0) { // Mostra errore ogni 3 tentativi
        appendMessage(`Errore nel monitoraggio (tentativo ${retryCount}). Riprovo...`, false);
      }
    }
  };

  // Avvia il polling
  pollingIntervalId = setInterval(checkStatus, POLLING_INTERVAL);
  console.log(`Inizio polling per lo stato del fine-tuning con jobId: ${jobId}`);
  checkStatus(); // Prima chiamata immediata

  // Cleanup function
  return () => {
    if (pollingIntervalId) {
      clearInterval(pollingIntervalId);
    }
  };
};

// Modifica la funzione handleEditRecord
const handleEditRecord = (index) => {
  const actualIndex = filteredData[index].originalIndex;
  
  setEditedData(prevData => {
    const newData = prevData ? [...prevData] : [...previewData];
    newData.forEach(row => row.isEditing = false);
    newData[actualIndex] = {
      ...newData[actualIndex],
      isEditing: true
    };
    return newData;
  });
  
  setIsEditMode(true);
  setShowActions(null);
};

// Modifica la funzione handleCellEdit
const handleCellEdit = (index, field, value) => {
  const actualIndex = filteredData[index].originalIndex;
  
  setEditedData(prevData => {
    if (!prevData) {
      const initialData = [...previewData];
      initialData[actualIndex] = {
        ...initialData[actualIndex],
        [field]: value,
        isEditing: true
      };
      return initialData;
    }

    const newData = [...prevData];
    newData[actualIndex] = {
      ...newData[actualIndex],
      [field]: value,
      isEditing: true
    };
    return newData;
  });
};

// Modifica la funzione handleAICorrection
const handleAICorrection = async (index) => {
  const actualIndex = filteredData[index].originalIndex;
  
  // Inizializza editedData se non esiste
  if (!editedData) {
    setEditedData([...previewData]);
    // Aggiungi un piccolo delay per assicurarti che editedData sia stato impostato
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  // Usa i dati da previewData se editedData non è ancora disponibile
  const row = editedData ? editedData[actualIndex] : previewData[actualIndex];
  
  if (!row.risposta || !row.domanda) {
    appendMessage(
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
        <span>Dati mancanti per la correzione AI</span>
      </div>,
      false
    );
    return;
  }

  appendMessage(
    <div className="flex items-center">
      <CpuChipIcon className="h-5 w-5 text-blue-500 mr-2" />
      <span>Ottimizzazione AI in corso...</span>
    </div>,
    false
  );

  try {
    const response = await axios.post(
      `${API_BASE_URL}/ai_correction`,
      {
        text: row.risposta,
        context: row.domanda,
        error_type: 'length'
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
    );

    if (response.data.corrected_text) {
      const updatedText = response.data.corrected_text;
      
      // Aggiorna editedData se esiste, altrimenti crealo
      setEditedData(prevData => {
        const newData = prevData ? [...prevData] : [...previewData];
        newData[actualIndex] = {
          ...newData[actualIndex],
          risposta: updatedText,
          status: 'valid'
        };
        return newData;
      });

      // Aggiorna anche previewData
      setPreviewData(prevData => {
        const newData = [...prevData];
        newData[actualIndex] = {
          ...newData[actualIndex],
          risposta: updatedText,
          status: 'valid'
        };
        return newData;
      });

      appendMessage(
        <div className="flex items-center">
          <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
          <span>
            Testo ottimizzato con successo!
            <br />
            <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>
              Lunghezza originale: {response.data.original_length} caratteri
              <br />
              Nuova lunghezza: {response.data.new_length} caratteri
              {response.data.source === 'cache' && " (dalla cache)"}
              <br />
              Lingua: {response.data.language === 'es' ? 'Spagnolo' : 'Italiano'}
            </span>
          </span>
        </div>,
        false
      );
    }
  } catch (error) {
    appendMessage(
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
        <span>Errore durante la correzione AI: {error.response?.data?.detail || error.message}</span>
      </div>,
      false
    );
  }
};

// Gestione cambio pagina
const handlePageChange = (newPage) => {
  setCurrentPage(newPage);
};

// Gestione cambio righe per pagina
const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(newRowsPerPage);
  setCurrentPage(1); // Reset alla prima pagina
};

// Nel componente Dashboard, aggiorna la gestione della chiusura
const handleCloseMenu = (index) => {
  const actualIndex = filteredData[index].originalIndex;
  
  // Ripristina lo stato originale della riga
  setEditedData(prevData => {
    if (!prevData) return prevData;
    
    const newData = [...prevData];
    // Disattiva la modalità di editing
    newData[actualIndex] = {
      ...previewData[actualIndex], // Ripristina i dati originali da previewData
      isEditing: false // Disattiva esplicitamente la modalità editing
    };
    return newData;
  });

  // Disattiva la modalità di editing globale se non ci sono altre righe in editing
  setIsEditMode(false);
  
  // Chiudi il menu delle azioni
  setShowActions(null);
};

// Aggiungi la funzione di validazione
const validateCell = (field, value) => {
  if (!value || !value.trim()) {
    return { 
      isValid: false, 
      message: `Il campo ${field === 'domanda' ? 'domanda' : 'risposta'} non può essere vuoto` 
    };
  }

  switch (field) {
    case 'risposta':
      if (value.length > 500) {
        return { 
          isValid: false, 
          message: "La risposta non può superare i 500 caratteri" 
        };
      }
      if (value.length < 10) {
        return { 
          isValid: false, 
          message: "La risposta deve contenere almeno 10 caratteri" 
        };
      }
      break;
      
    case 'domanda':
      if (value.length > 150) {
        return { 
          isValid: false, 
          message: "La domanda non può superare i 150 caratteri" 
        };
      }
      if (value.length < 5) {
        return { 
          isValid: false, 
          message: "La domanda deve contenere almeno 5 caratteri" 
        };
      }
      break;
      
    default:
      return { isValid: false, message: "Campo non valido" };
  }

  return { isValid: true, message: "" };
};

// Nel componente principale, modifica la logica di visualizzazione del menu
const handleShowActions = (index, totalRows) => {
  const isLastRows = index >= totalRows - 3;
  const isFirstRow = index === 0;
  const isFilterActive = showErrorsOnly;
  
  let menuPos = 'bottom';
  
  // Se è la prima riga e il filtro è attivo, posiziona il menu a sinistra
  if (isFirstRow && isFilterActive) {
    menuPos = 'bottom-shifted';
  } 
  // Se sono le ultime righe, posiziona il menu sopra
  else if (isLastRows) {
    menuPos = 'top';
  }
  
  setMenuPosition(menuPos);
  setShowActions(index);
};

const handleSaveEdit = (index, field, value) => {
  const actualIndex = filteredData[index].originalIndex;
  
  // Valida il contenuto prima di salvare
  const validation = validateCell(field, value);
  if (!validation.isValid) {
    appendMessage(
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
        <span>{validation.message}</span>
      </div>,
      false
    );
    return;
  }

  // Aggiorna editedData
  setEditedData(prevData => {
    const newData = [...prevData];
    newData[actualIndex] = {
      ...newData[actualIndex],
      [field]: value,
      isEditing: false,
      status: 'valid'
    };
    return newData;
  });

  // Aggiorna anche previewData
  setPreviewData(prevData => {
    const newData = [...prevData];
    newData[actualIndex] = {
      ...newData[actualIndex],
      [field]: value,
      status: 'valid'
    };
    return newData;
  });

  appendMessage(
    <div className="flex items-center">
      <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
      <span>Modifiche salvate con successo</span>
    </div>,
    false
  );
};

const handleCancelEdit = (index) => {
  const actualIndex = filteredData[index].originalIndex;
  
  setEditedData(prevData => {
    const newData = [...prevData];
    newData[actualIndex] = {
      ...previewData[actualIndex],
      isEditing: false
    };
    return newData;
  });
};

// Calcola il numero di errori
const errorCount = React.useMemo(() => {
  if (!previewData) return 0;
  return previewData.filter(row => row.status === 'invalid').length;
}, [previewData]);

// Filtra i dati in base a showErrorsOnly
const filteredData = React.useMemo(() => {
  const startIndex = (currentPage - 1) * rowsPerPage;
  let dataToUse = isEditMode ? editedData : previewData;
  
  // Aggiungi gli indici originali prima del filtraggio
  const dataWithIndices = dataToUse.map((row, originalIndex) => ({
    ...row,
    originalIndex // Mantieni l'indice originale
  }));
  
  // Applica il filtro se necessario
  const filteredRows = showErrorsOnly 
    ? dataWithIndices.filter(row => row.status === 'invalid')
    : dataWithIndices;
  
  // Applica la paginazione
  return filteredRows.slice(startIndex, startIndex + Math.min(rowsPerPage, filteredRows.length));
}, [currentPage, rowsPerPage, previewData, editedData, isEditMode, showErrorsOnly]);

// Handler per il bottone "Correggi File"
const handleShowErrorsOnly = () => {
  setShowErrorsOnly(true);
  setCurrentPage(1); // Resetta alla prima pagina quando filtriamo
};

// Modifica la funzione handleProceed per gestire correttamente i tipi di dati
const handleProceed = async () => {
  if (errorCount > 0) return;
  
  try {
    setIsProcessing(true);
    appendMessage(
      <div className="flex items-center">
        <CpuChipIcon className="h-5 w-5 text-blue-500 mr-2" />
        <span>Conversione del dataset in formato JSONL...</span>
      </div>,
      false
    );

    // Crea il CSV nello stesso formato usato nei messaggi di sistema
    const csvContent = [
      'domanda,risposta', // header
      ...previewData.map(row => `${row.domanda},${row.risposta}`)
    ].join('\n');
    
    const formData = new FormData();
    formData.append('file', new Blob([csvContent], { type: 'text/csv' }), selectedFiles[0]?.name || 'dataset.csv');
    formData.append('use_expansion', useExpansion ? 'true' : 'false');
    formData.append('num_variants', numVariants.toString());
    formData.append('purpose', 'assistenza_clienti_primo_livello');

    const response = await axios.post(
      `${API_BASE_URL}/convert_to_json`,
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.data.task_id) {
      monitorTaskStatus(response.data.task_id);
      appendMessage(
        <div className="flex items-center">
          <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
          <span>Processo di conversione avviato</span>
        </div>,
        false
      );
    }
  } catch (error) {
    appendMessage(
      <div className="flex items-center">
        <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
        <span>
          Errore durante la conversione: {error.response?.data?.detail || error.message}
        </span>
      </div>,
      false
    );
  } finally {
    setIsProcessing(false);
  }
};

// Handler per mostrare tutti i record
const handleShowAll = () => {
  setShowErrorsOnly(false);
  setCurrentPage(1); // Resetta alla prima pagina
};

// Aggiungi questa funzione nel componente Dashboard prima di handleProceed
const monitorTaskStatus = async (taskId) => {
  const maxAttempts = 180; // 15 minuti con intervallo di 5 secondi
  const interval = 5000;
  let attempts = 0;

  const checkStatus = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.state === 'SUCCESS') {
        appendMessage(
          <div className="flex items-center">
            <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
            <span>Conversione completata con successo!</span>
          </div>,
          false
        );

        if (response.data.jsonl_path) {
          setJsonFilePath(response.data.jsonl_path);
          setJsonPreview(response.data.preview);
          
          // Mostra il bottone per procedere al fine-tuning
          appendMessage(
            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleSendForFinetuning(response.data.jsonl_path)}
                className={`px-4 py-2 rounded-md text-white ${
                  isDarkMode 
                    ? 'bg-green-600 hover:bg-green-700' 
                    : 'bg-green-500 hover:bg-green-600'
                }`}
              >
                Procedi al Fine-tuning
              </button>
            </div>,
            false
          );
        }
        return;
      }

      if (response.data.state === 'FAILURE') {
        appendMessage(
          <div className="flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
            <span>Errore durante la conversione: {response.data.error}</span>
          </div>,
          false
        );
        return;
      }

      if (response.data.state === 'PROCESSING') {
        attempts++;
        if (attempts < maxAttempts) {
          setTimeout(checkStatus, interval);
        } else {
          appendMessage(
            <div className="flex items-center">
              <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-2" />
              <span>
                Il processo sta richiedendo più tempo del previsto. 
                Continuerà in background e riceverai una notifica al completamento.
              </span>
            </div>,
            false
          );
        }
      }
    } catch (error) {
      appendMessage(
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
          <span>
            Errore durante il monitoraggio: {error.response?.data?.detail || error.message}
          </span>
        </div>,
        false
      );
    }
  };

  // Avvia il monitoraggio
  checkStatus();
};

// ... nel codice che gestisce la preview JSONL
const handleTaskCompletion = (result) => {
  if (result.json_preview) {
    setJsonPreview(result.json_preview);  // Questo mostrerà tutti i record ricevuti
  }
};

return (
    <div className={`min-h-screen transition-colors duration-200 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <DashboardHeader isDarkMode={isDarkMode} onLogout={onLogout} />
      
      {previewData.length > 0 && (
        <StatusBar
          hasErrors={errorCount > 0}
          errorCount={errorCount}
          onShowErrorsOnly={handleShowErrorsOnly}
          onShowAll={handleShowAll}
          showErrorsOnly={showErrorsOnly}
          onProceed={handleGenerateJson} // Logica per conversione JSONL
          onSendForFinetuning={handleSendForFinetuning} // Funzione per il fine-tuning
          jsonFilePath={jsonFilePath} // Nuovo prop che indica che la conversione JSONL è completata
          isDarkMode={isDarkMode}
          isProcessing={isProcessing}
        />
      )}
      
      <div className="py-2 max-w-7xl mx-auto pt-24 pb-24"> {/* Aggiunto pb-24 */}
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className={`shadow overflow-hidden sm:rounded-lg transition-colors duration-200 ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-4">
                    {/* Dataset Expansion Options */}
                    <div className="mt-4">
                      <h3 className={`text-lg font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>Opzioni di espansione del dataset</h3>
                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            checked={useExpansion}
                            onChange={(e) => setUseExpansion(e.target.checked)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className={`ml-2 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-700'
                          }`}>Usa espansione del dataset</span>
                        </label>
                      </div>
                      {useExpansion && (
                        <div className="mt-2">
                          <label className={`block text-sm font-medium transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-300' : 'text-gray-700'
                          }`}>
                            Numero di varianti per domanda/risposta
                          </label>
                          <input
                            type="number"
                            min="10"
                            max="100"
                            value={numVariants}
                            onChange={(e) => setNumVariants(parseInt(e.target.value))}
                            className={`mt-1 block w-full rounded-md shadow-sm transition-colors duration-200 ${
                              isDarkMode 
                                ? 'bg-gray-700 border-gray-600 text-white' 
                                : 'border-gray-300 text-gray-900'
                            } focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                          />
                        </div>
                      )}
                    </div>

                    {/* Template Format Selection */}
                    <div className="mt-4">
                      <label className={`block text-sm font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        Seleziona il formato del template
                      </label>
                      <select
                        className={`mt-1 block w-full pl-3 pr-10 py-2 text-base rounded-md transition-colors duration-200 ${
                          isDarkMode 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'border-gray-300 text-gray-900'
                        } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                        onChange={(e) => setTemplateFormat(e.target.value)}
                      >
                        <option value="csv">CSV</option>
                        <option value="word">Word</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </div>

                    {/* Template Download Button */}
                    <div className="mt-4">
                      <button
                        onClick={handleDownloadTemplate}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                        Scarica Template
                      </button>
                    </div>

                    {/* File Upload Section */}
                    <div>
                      <label className={`block text-sm font-medium transition-colors duration-200 ${
                        isDarkMode ? 'text-gray-300' : 'text-gray-700'
                      }`}>
                        Carica file
                      </label>
                      <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md transition-colors duration-200 ${
                        isDarkMode 
                          ? 'border-gray-600 bg-gray-700' 
                          : 'border-gray-300 bg-white'
                      }`}>
                        <div className="space-y-1 text-center">
                          <ArrowUpTrayIcon className={`mx-auto h-8 w-8 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-500' : 'text-gray-400'
                          }`} />
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className={`relative cursor-pointer rounded-md font-medium transition-colors duration-200 ${
                                isDarkMode 
                                  ? 'text-indigo-400 hover:text-indigo-300' 
                                  : 'text-indigo-600 hover:text-indigo-500'
                              } focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`}
                            >
                              <span>Carica un file</span>
                              <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} multiple />
                            </label>
                            <p className={`pl-1 transition-colors duration-200 ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-600'
                            }`}>o trascina e rilascia</p>
                          </div>
                          <p className={`text-xs transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-400' : 'text-gray-500'
                          }`}>
                            CSV, Excel, Word, PDF, JSON, JSONL fino a 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Selected Files List */}
                    {selectedFiles.length > 0 && (
                      <div>
                        <h4 className={`text-sm font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-gray-300' : 'text-gray-700'
                        }`}>File selezionati:</h4>
                        <ul className={`mt-2 border rounded-md divide-y transition-colors duration-200 ${
                          isDarkMode 
                            ? 'border-gray-600 divide-gray-600' 
                            : 'border-gray-200 divide-gray-200'
                        }`}>
                          {selectedFiles.map((file, index) => (
                            <li key={index} className="pl-3 pr-4 py-2 flex items-center justify-between text-sm">
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon className={`flex-shrink-0 h-5 w-5 transition-colors duration-200 ${
                                  isDarkMode ? 'text-gray-400' : 'text-gray-400'
                                }`} />
                                <span className={`ml-2 flex-1 w-0 truncate transition-colors duration-200 ${
                                  isDarkMode ? 'text-gray-300' : 'text-gray-900'
                                }`}>
                                  {file.name}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Upload Button */}
                    <div className="flex space-x-4">
                      <button
                        onClick={handleFileUpload}
                        disabled={isProcessing}
                        className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                          isProcessing 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : isDarkMode 
                              ? 'bg-blue-600 hover:bg-blue-700' 
                              : 'bg-blue-600 hover:bg-blue-700'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        <ArrowUpTrayIcon className="mr-2 h-4 w-4" />
                        {isProcessing ? 'Elaborazione in corso...' : 'Carica File e Visualizza Anteprima'}
                      </button>
                    </div>

                    {/* Data Preview */}
                    {previewData.length > 0 && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Anteprima dei Dati</h3>
                        
                        {/* Aggiungiamo controlli per la paginazione */}
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex items-center space-x-2">
                            <select 
                              className={`rounded-md border-gray-300 shadow-sm transition-colors duration-200 ${
                                isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'
                              }`}
                              onChange={(e) => setRowsPerPage(Number(e.target.value))}
                              value={rowsPerPage}
                            >
                              <option value={10}>10 righe</option>
                              <option value={25}>25 righe</option>
                              <option value={50}>50 righe</option>
                            </select>
                            <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                              Righe per pagina
                            </span>
                          </div>
                          
                          <div className="flex items-center space-x-2">
                            <button
                              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                              disabled={currentPage === 1}
                              className={`px-2 py-1 rounded ${
                                isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
                              } disabled:opacity-50`}
                            >
                              Precedente
                            </button>
                            <span className={isDarkMode ? 'text-white' : 'text-gray-800'}>
                              Pagina {currentPage} di {Math.ceil(previewData.length / rowsPerPage)}
                            </span>
                            <button
                              onClick={() => setCurrentPage(prev => prev + 1)}
                              disabled={currentPage >= Math.ceil(previewData.length / rowsPerPage)}
                              className={`px-2 py-1 rounded ${
                                isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
                              } disabled:opacity-50`}
                            >
                              Successiva
                            </button>
                          </div>
                        </div>

                        {/* Tabella con scroll orizzontale e verticale */}
                        <div className="mt-6 flow-root">
                          <div className="rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden">
                            <div className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                              {/* Table Header */}
                              <div className="bg-gray-50 dark:bg-gray-800">
                                <div className="grid grid-cols-12 divide-x divide-gray-200 dark:divide-gray-700">
                                  <div className="col-span-4 px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white"> {/* Cambiato da col-span-5 a col-span-4 */}
                                    Domanda
                                  </div>
                                  <div className="col-span-6 px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">
                                    Risposta
                                  </div>
                                  <div className="col-span-2 px-6 py-3 text-center text-sm font-semibold text-gray-900 dark:text-white"> {/* Cambiato da col-span-1 a col-span-2 */}
                                    Azioni
                                  </div>
                                </div>
                              </div>

                              {/* Table Body */}
                              <div className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
                                {filteredData.map((row, index) => (
                                  <div key={row.originalIndex} className={`grid grid-cols-12 divide-x divide-gray-200 dark:divide-gray-700 ${
                                    row.status === 'valid'
                                      ? isDarkMode 
                                        ? 'bg-green-900/20 text-white' 
                                        : 'bg-green-50 text-gray-900'
                                      : isDarkMode 
                                        ? 'bg-red-900/20 text-white' 
                                        : 'bg-red-50 text-gray-900'
                                  }`}>
                                    <div className="col-span-4 px-6 py-4"> {/* Cambiato da col-span-5 a col-span-4 */}
                                      <TableCell 
                                        content={row.domanda} 
                                        isEditing={row.isEditing} 
                                        onChange={handleCellEdit}
                                        onSave={handleSaveEdit}
                                        onCancel={handleCancelEdit}
                                        isDarkMode={isDarkMode} 
                                        type="domanda"
                                        index={index}  // L'indice visuale rimane lo stesso
                                      />
                                    </div>
                                    <div className="col-span-6 px-6 py-4">
                                      <TableCell 
                                        content={row.risposta} 
                                        isEditing={row.isEditing} 
                                        onChange={handleCellEdit}
                                        onSave={handleSaveEdit}
                                        onCancel={handleCancelEdit}
                                        isDarkMode={isDarkMode} 
                                        type="risposta"
                                        index={index}
                                      />
                                    </div>
                                    <div className="col-span-2 px-6 py-4 relative"> {/* Cambiato da col-span-1 a col-span-2 */}
                                      <button
                                        onClick={() => handleShowActions(index, filteredData.length)}
                                        className={`inline-flex items-center p-2 rounded-full ${
                                          isDarkMode
                                            ? 'hover:bg-gray-700 text-gray-300'
                                            : 'hover:bg-gray-100 text-gray-600'
                                        }`}
                                      >
                                        <EllipsisVerticalIcon className="h-5 w-5" />
                                      </button>
                                      
                                      {showActions === index && (
                                        <ActionMenu
                                          onEdit={() => handleEditRecord(index)}
                                          onDelete={() => handleDeleteRecord(index)}
                                          onAIFix={() => handleAICorrection(index)}
                                          onCancel={() => handleCloseMenu(index)}
                                          isDarkMode={isDarkMode}
                                          position={menuPosition} // Passa la posizione al menu
                                        />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Processing Progress */}
                    {isProcessing && (
                      <div className="mt-4">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                          <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            Elaborazione in corso...
                          </span>
                        </div>
                      </div>
                    )}

                    {/* JSON Preview */}
                    {jsonPreview && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Anteprima del JSONL generato</h3>
                        <pre className={`mt-2 p-4 border rounded-md text-sm overflow-auto max-h-60 transition-colors duration-200 ${
                          isDarkMode 
                            ? 'bg-gray-800 border-gray-700 text-gray-300' 
                            : 'bg-gray-100 border-gray-200 text-gray-800'
                        }`}>
                          {jsonPreview.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </pre>
                      </div>
                    )}

                    {/* System Messages */}
                    {messages.length > 0 && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Messaggi del sistema</h3>
                        <div className={`mt-2 border rounded-md divide-y max-h-60 overflow-y-auto transition-colors duration-200 ${
                          isDarkMode 
                            ? 'border-gray-700 divide-gray-700' 
                            : 'border-gray-200 divide-gray-200'
                        }`}>
                          {messages.map((message, index) => (
                            <div key={index} className={`p-3 transition-colors duration-200 ${
                              message.isUser 
                                ? isDarkMode ? 'bg-blue-900 bg-opacity-50' : 'bg-blue-50'
                                : isDarkMode ? 'bg-gray-800' : 'bg-white'
                            }`}>
                              <p className={`text-sm flex items-start transition-colors duration-200 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}>
                                {message.isUser ? (
                                  <ExclamationCircleIcon className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                                ) : (
                                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                                )}
                                {message.content}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Fine-tuning Status */}
                    {fineTuneStatus && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Stato del Fine-Tuning</h3>
                        <div className="mt-2">
                          {fineTuneStatus === 'running' && (
                            <div className="flex items-center">
                              <div className="w-5 h-5 mr-2 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-gray-300' : 'text-gray-700'
                              }`}>Fine-tuning in corso...</p>
                            </div>
                          )}
                          {fineTuneStatus === 'succeeded' && (
                            <div className="flex items-center">
                              <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2" />
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-green-400' : 'text-green-700'
                              }`}>Fine-tuning completato con successo!</p>
                            </div>
                          )}
                          {fineTuneStatus === 'failed' && (
                            <div className="flex items-center">
                              <ExclamationCircleIcon className="h-6 w-6 text-red-500 mr-2" />
                              <p className={`transition-colors duration-200 ${
                                isDarkMode ? 'text-red-400' : 'text-red-700'
                              }`}>Fine-tuning fallito. Riprova più tardi.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Fine-tuning Progress */}
                    {isFineTuning && (
                      <div className="mt-4">
                        <h3 className={`text-lg font-medium transition-colors duration-200 ${
                          isDarkMode ? 'text-white' : 'text-gray-900'
                        }`}>Progresso del Fine-Tuning</h3>
                        <div className="mt-2 relative pt-1">
                          <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded transition-colors duration-200 ${
                            isDarkMode ? 'bg-blue-900' : 'bg-blue-200'
                          }`}>
                            <div 
                              style={{ width: `${progress}%` }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
                            ></div>
                          </div>
                          <div className="text-right">
                            <span className={`text-sm font-semibold inline-block transition-colors duration-200 ${
                              isDarkMode ? 'text-blue-400' : 'text-blue-600'
                            }`}>
                              {progress}%
                            </span>
                          </div>
                        </div>
                        <p className={`text-sm mt-2 transition-colors duration-200 ${
                          isDarkMode ? 'text-gray-400' : 'text-gray-600'
                        }`}>
                          Tempo trascorso: {formatElapsedTime(elapsedTime)}
                        </p>
                        {showInfoMessage && (
                          <p className={`text-sm mt-2 transition-colors duration-200 ${
                            isDarkMode ? 'text-gray-400' : 'text-gray-600'
                          }`}>
                            Il processo di fine-tuning può richiedere molto tempo, specialmente durante la fase 'running'. Si prega di pazientare.
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="pb-20" /> {/* Aggiunge spazio per evitare che il contenuto venga nascosto dalla barra fissa */}
    </div>
  );
};

export default Dashboard;