import React, { useState } from 'react';
import WidgetList from './WidgetList';
import WidgetCustomizer from '../widget-customizer/WidgetCustomizer';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

export default function WidgetManager({ token, isDarkMode }) {
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);

  const handleEdit = (widget) => {
    setSelectedWidget(widget);
    setIsEditing(true);
  };

  const handleDelete = () => {
    setReloadFlag(prev => !prev);
    setSelectedWidget(null);
    setIsEditing(false);
  };

  const handleClose = () => {
    setSelectedWidget(null);
    setIsEditing(false);
  };

  const handleSave = async (config) => {
    try {
      await axios.put(
        `${API_BASE_URL}/widget/${selectedWidget.id}`,
        config,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      toast.success('Widget aggiornato con successo');
      setNeedsRefresh(prev => !prev);
    } catch (error) {
      console.error('Errore durante l\'aggiornamento:', error);
      toast.error('Errore durante l\'aggiornamento del widget');
    }
  };

  return (
    <div className={`container mx-auto px-4 py-8 relative ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
      <div className="mb-8 flex justify-between items-center">
        <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          I tuoi Widget
        </h2>
      </div>

      <WidgetList
        token={token}
        onEdit={handleEdit}
        onDelete={handleDelete}
        isDarkMode={isDarkMode}
        refreshTrigger={needsRefresh}
      />

      {isEditing && selectedWidget && (
        <div className={`fixed inset-0 z-50 ${isDarkMode ? 'bg-black/50' : 'bg-gray-900/50'} backdrop-blur-sm overflow-y-auto`}>
          <div className="flex items-start justify-center min-h-screen p-4">
            <div className={`w-full max-w-4xl rounded-xl shadow-2xl ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`}>
              <div className="max-h-[90vh] flex flex-col">
                <div className="flex-1 overflow-y-auto p-6">
                  <WidgetCustomizer
                    playgroundConfig={{
                      model: selectedWidget.model_name,
                      systemPrompt: selectedWidget.system_prompt,
                      temperature: selectedWidget.temperature,
                      maxTokens: selectedWidget.max_tokens,
                      useRag: selectedWidget.use_rag,
                      ragPrompt: selectedWidget.rag_prompt,
                      activeRagDocuments: selectedWidget.rag_document_ids
                    }}
                    existingWidget={selectedWidget}
                    onClose={handleClose}
                    onSave={handleSave}
                    token={token}
                    isDarkMode={isDarkMode}
                  />
                </div>
                
                <div className="border-t p-4 bg-inherit">
                  <button
                    onClick={handleClose}
                    className="float-right px-6 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
                  >
                    Chiudi
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 