import React from 'react';
import WidgetManager from './WidgetManager';

export default function WidgetsPage({ token, isDarkMode }) {
  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <WidgetManager token={token} isDarkMode={isDarkMode} />
        </div>
      </div>
    </div>
  );
} 