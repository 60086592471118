import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import Chatbot from './Chatbot';
import Login from './Login';
import ModelPlayground from './model-playground';
import EmbeddingsInterface from './EmbeddingsInterface';
import RAGSearchInterface from './components/rag/RAGSearchInterface';
import EnhancedRAGInterface from './components/rag/EnhancedRAGInterface';
import { Sun as SunIcon, Moon as MoonIcon } from 'lucide-react';
import APISettingsPanel from './components/settings/APISettingsPanel';
import WidgetsPage from './components/widget-manager/WidgetsPage';
import Navbar from './components/Navbar';
import WebsiteAnalyzer from './components/WebsiteAnalyzer/WebsiteAnalyzer';
import { AuthProvider } from './contexts/AuthContext';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [currentView, setCurrentView] = useState('dashboard');
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') === 'dark' || 
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem('darkMode', isDarkMode);
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentView('dashboard');
  };

  const toggleView = () => {
    setCurrentView(current => {
      switch (current) {
        case 'dashboard':
          return 'chatbot';
        case 'chatbot':
          return 'playground';
        case 'playground':
          return 'embeddings';
        case 'embeddings':
          return 'rag-enhanced';
        case 'rag-enhanced':
          return 'website-analyzer';
        case 'website-analyzer':
          return 'settings';
        case 'settings':
          return 'dashboard';
        default:
          return 'dashboard';
      }
    });
  };

  const getToggleButtonText = () => {
    switch (currentView) {
      case 'dashboard':
        return 'Switch to Chatbot';
      case 'chatbot':
        return 'Switch to Playground';
      case 'playground':
        return 'Switch to Embeddings';
      case 'embeddings':
        return 'Switch to Enhanced RAG';
      case 'rag-enhanced':
        return 'Switch to Website Analyzer';
      case 'website-analyzer':
        return 'Switch to Settings';
      case 'settings':
        return 'Switch to Dashboard';
      default:
        return 'Switch to Dashboard';
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (!isLoggedIn) {
    return <Login setIsLoggedIn={setIsLoggedIn} setToken={setToken} isDarkMode={isDarkMode} />;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="min-h-screen transition-colors duration-200 dark:bg-gray-900 bg-gray-100">
          <Navbar 
            isDarkMode={isDarkMode} 
            toggleDarkMode={toggleDarkMode}
            toggleSidebar={toggleSidebar}
            setCurrentView={setCurrentView}
            currentView={currentView}
            toggleView={toggleView}
          />

          <div className="container mx-auto p-6">
            <div className="transition-colors duration-200 dark:bg-gray-800 bg-white rounded-lg shadow-lg p-6">
              {currentView === 'dashboard' ? (
                <Dashboard 
                  isLoggedIn={isLoggedIn} 
                  token={token} 
                  onLogout={handleLogout}
                  isDarkMode={isDarkMode}
                />
              ) : currentView === 'chatbot' ? (
                <Chatbot token={token} isDarkMode={isDarkMode} />
              ) : currentView === 'widgets' ? (
                <WidgetsPage token={token} isDarkMode={isDarkMode} />
              ) : currentView === 'playground' ? (
                <ModelPlayground token={token} isDarkMode={isDarkMode} />
              ) : currentView === 'embeddings' ? (
                <EmbeddingsInterface 
                  isDarkMode={isDarkMode}
                  datasetId="your-dataset-id"
                  onComplete={() => console.log('Analisi completata')}
                />
              ) : currentView === 'rag-simple' ? (
                <RAGSearchInterface
                  modelId={null}
                  isDarkMode={isDarkMode}
                />
              ) : currentView === 'website-analyzer' ? (
                <WebsiteAnalyzer 
                  isDarkMode={isDarkMode}
                  onDatasetGenerated={(data) => {
                    console.log('Dataset generato:', data);
                  }}
                />
              ) : currentView === 'settings' ? (
                <APISettingsPanel isDarkMode={isDarkMode} />
              ) : (
                <EnhancedRAGInterface
                  isDarkMode={isDarkMode}
                />
              )}
            </div>
          </div>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;