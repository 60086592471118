import React, { useState } from 'react';
import { ClipboardDocumentIcon, CheckIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';

export default function CodePreview({ config }) {
  const [copied, setCopied] = useState(false);

  // Verifica che la configurazione sia valida
  if (!config) {
    return (
      <div className="p-4 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
        Configurazione non disponibile. Completa la configurazione del widget.
      </div>
    );
  }

  // Verifica che l'API key sia presente
  if (!config.api_key && !config.existingWidget?.api_key) {
    return (
      <div className="p-4 bg-red-50 border-l-4 border-red-400 text-red-700">
        API key mancante. Salva il widget prima di visualizzare il codice.
      </div>
    );
  }

  // Genera il codice di integrazione
  const generateEmbedCode = () => {
    const apiKey = config.api_key || config.existingWidget?.api_key;
    
    const scriptCode = `
<!-- FineTuningMax Widget -->
<script>
  window.finetuningmaxConfig = {
    apiKey: "${apiKey}",
    position: "${config.position || 'bottom-right'}",
    theme: ${JSON.stringify(config.theme || {
        primary_color: "#2563eb",
        background_color: "#ffffff",
        text_color: "#000000",
        font_family: "system-ui"
    }, null, 2)},
    welcomeMessage: "${config.welcome_message || 'Ciao! Come posso aiutarti?'}",
    placeholderText: "${config.placeholder_text || 'Scrivi un messaggio...'}",
    customCSS: \`${config.custom_css || ''}\`
  };
</script>
<script 
  async 
  src="${process.env.REACT_APP_API_BASE_URL || 'https://finetuningmax.com/api'}/widget/${apiKey}/widget.js"
  defer
></script>`;

    return scriptCode.trim();
  };

  // Gestisce la copia del codice
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(generateEmbedCode());
      setCopied(true);
      toast.success('Codice copiato negli appunti!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast.error('Errore durante la copia del codice');
    }
  };

  return (
    <div className="space-y-4">
      {/* Istruzioni */}
      <div className="prose prose-sm max-w-none">
        <h4>Come integrare il widget</h4>
        <ol className="list-decimal pl-4 space-y-2">
          <li>
            Copia il codice sottostante
          </li>
          <li>
            Incolla il codice nel tuo sito web, appena prima del tag <code>&lt;/body&gt;</code>
          </li>
        </ol>
      </div>

      {/* Code Block */}
      <div className="relative">
        <pre className="bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto text-sm">
          <code>{generateEmbedCode()}</code>
        </pre>

        {/* Copy Button */}
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 p-2 rounded-md bg-white/10 hover:bg-white/20 transition-colors"
          title="Copia negli appunti"
        >
          {copied ? (
            <CheckIcon className="h-5 w-5 text-green-400" />
          ) : (
            <ClipboardDocumentIcon className="h-5 w-5 text-gray-400" />
          )}
        </button>
      </div>

      {/* Note aggiuntive */}
      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 text-sm">
        <h5 className="font-medium text-blue-800">Note:</h5>
        <ul className="mt-2 space-y-1 text-blue-700">
          <li>• Il widget si caricherà automaticamente quando la pagina è pronta</li>
          <li>• La configurazione può essere modificata in qualsiasi momento dal pannello di controllo</li>
          {config.allowed_domains?.length > 0 && (
            <li className="text-yellow-700">
              • Questo widget funzionerà solo sui domini autorizzati: {config.allowed_domains.join(', ')}
            </li>
          )}
        </ul>
      </div>

      {/* Rate Limit Info */}
      <div className="bg-gray-50 p-4 rounded-lg text-sm">
        <p className="text-gray-600">
          Rate Limit: <span className="font-medium">{config.rate_limit}</span> richieste al minuto
        </p>
      </div>
    </div>
  );
} 