import React from 'react';
import { SunIcon, MoonIcon, Bars3Icon, SquaresPlusIcon } from '@heroicons/react/24/outline';

const Navbar = ({ isDarkMode, toggleDarkMode, toggleSidebar, setCurrentView, currentView, toggleView }) => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const getToggleButtonText = () => {
    switch (currentView) {
      case 'dashboard':
        return 'Switch to Chatbot';
      case 'chatbot':
        return 'Switch to Playground';
      case 'playground':
        return 'Switch to Embeddings';
      case 'embeddings':
        return 'Switch to Enhanced RAG';
      case 'rag-enhanced':
        return 'Switch to Settings';
      case 'settings':
        return 'Switch to Dashboard';
      default:
        return 'Switch to Dashboard';
    }
  };

  return (
    <nav className={`transition-colors duration-200 ${
      isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
    } shadow-md`}>
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Left side */}
          <div className="flex items-center space-x-4">
            <button
              onClick={toggleSidebar}
              className={`p-2 rounded-md transition-colors duration-200 ${
                isDarkMode 
                  ? 'hover:bg-gray-700' 
                  : 'hover:bg-gray-100'
              }`}
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
            
            {/* Logo o titolo */}
            <span className="font-semibold text-lg">FineTuningMax</span>
          </div>

          {/* Right side */}
          <div className="flex items-center space-x-4">
            {/* Switch button */}
            <button
              onClick={toggleView}
              className={`py-2 px-4 rounded-md transition-colors duration-200 ${
                isDarkMode 
                  ? 'hover:bg-gray-700' 
                  : 'hover:bg-gray-100'
              }`}
            >
              {getToggleButtonText()}
            </button>

            {/* Widget button */}
            <button
              onClick={() => setCurrentView('widgets')}
              className={`flex items-center space-x-2 py-2 px-4 rounded-md transition-colors duration-200 ${
                isDarkMode 
                  ? 'hover:bg-gray-700' 
                  : 'hover:bg-gray-100'
              }`}
            >
              <SquaresPlusIcon className="h-5 w-5" />
              <span>I miei Widget</span>
            </button>

            {/* Theme toggle */}
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-gray-700 text-yellow-400 hover:bg-gray-600' 
                  : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
              }`}
              aria-label={isDarkMode ? 'Attiva tema chiaro' : 'Attiva tema scuro'}
            >
              {isDarkMode ? (
                <SunIcon className="h-5 w-5" />
              ) : (
                <MoonIcon className="h-5 w-5" />
              )}
            </button>

            {/* Logout button */}
            <button
              onClick={handleLogout}
              className={`py-2 px-4 rounded-md transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-red-600 hover:bg-red-700 text-white'
                  : 'bg-red-500 hover:bg-red-600 text-white'
              }`}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 