import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TrashIcon, PencilIcon, ClipboardDocumentIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid';
import { format, isValid } from 'date-fns';
import { toast } from 'react-hot-toast';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

// Funzione helper per formattare le date in modo sicuro
const formatDate = (dateString, formatStr) => {
  if (!dateString) return '-';
  
  try {
    const date = new Date(dateString);
    
    if (isNaN(date.getTime()) || !isValid(date)) {
      console.error('Data non valida:', dateString);
      return 'Data non valida';
    }
    
    return format(date, formatStr);
  } catch (e) {
    console.error('Errore formattazione:', dateString, e);
    return '-';
  }
};

export default function WidgetList({ token, onEdit, onDelete, isDarkMode, refreshTrigger }) {
  const [widgets, setWidgets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editedName, setEditedName] = useState('');

  const loadWidgets = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/widget/list`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (response.data && Array.isArray(response.data)) {
        setWidgets(response.data);
      }
    } catch (err) {
      setError(err.response?.data?.detail || 'Errore nel caricamento dei widget');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) loadWidgets();
  }, [token, refreshTrigger]);

  const handleDelete = async (widgetId) => {
    if (!window.confirm('Sei sicuro di voler eliminare questo widget?')) return;

    try {
      await axios.delete(`${API_BASE_URL}/widget/${widgetId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      await loadWidgets();
      if (onDelete) onDelete(widgetId);
    } catch (err) {
      console.error('Errore durante l\'eliminazione:', err);
      alert('Errore durante l\'eliminazione del widget');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedId(text);
    toast.success('ID copiato negli appunti', {
      position: 'bottom-center',
      duration: 2000,
      icon: '📋',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
    
    // Reset copied state after 2 seconds
    setTimeout(() => {
      setCopiedId(null);
    }, 2000);
  };

  const saveWidgetName = async (widget) => {
    try {
      await axios.patch(`${API_BASE_URL}/widget/${widget.id}`, 
        { widget_name: editedName },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      await loadWidgets();
      toast.success('Nome aggiornato con successo');
    } catch (err) {
      toast.error('Errore durante il salvataggio');
    } finally {
      setEditingId(null);
      setEditedName('');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 text-red-700 rounded-lg">
        {error}
      </div>
    );
  }

  if (widgets.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-gray-500">Nessun widget creato</p>
      </div>
    );
  }

  return (
    <div className={`overflow-x-auto rounded-lg border ${
      isDarkMode ? 'border-gray-700' : 'border-gray-200'
    }`}>
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
        <thead className={
          isDarkMode ? 'bg-gray-800' : 'bg-gray-50'
        }>
          <tr>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Nome Widget
            </th>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              ID Widget
            </th>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Modello
            </th>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Stato
            </th>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Creato il
            </th>
            <th className={`px-6 py-3 text-left text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Ultimo utilizzo
            </th>
            <th className={`px-6 py-3 text-right text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-300' : 'text-gray-500'
            }`}>
              Azioni
            </th>
          </tr>
        </thead>
        
        <tbody className={`divide-y ${
          isDarkMode ? 'divide-gray-700 bg-gray-900' : 'divide-gray-200 bg-white'
        }`}>
          {widgets.map((widget) => (
            <tr key={widget.id} className={`${
              isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-50'
            }`}>
              <td className={`px-6 py-4 whitespace-nowrap ${
                isDarkMode ? 'text-gray-100' : 'text-gray-900'
              }`}>
                <div className="flex items-center gap-2">
                  {editingId === widget.id ? (
                    <input
                      type="text"
                      className={`flex-1 p-1 rounded border ${
                        isDarkMode 
                          ? 'bg-gray-800 border-gray-600' 
                          : 'bg-white border-gray-300'
                      }`}
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      onBlur={() => saveWidgetName(widget)}
                      onKeyPress={(e) => e.key === 'Enter' && saveWidgetName(widget)}
                      autoFocus
                    />
                  ) : (
                    <div className="flex-1">
                      <span className="cursor-text">
                        {widget.widget_name || 'Senza nome'}
                      </span>
                    </div>
                  )}
                  
                  <button
                    onClick={() => {
                      setEditingId(widget.id);
                      setEditedName(widget.widget_name);
                    }}
                    className={`p-1 rounded hover:bg-opacity-20 ${
                      isDarkMode 
                        ? 'text-gray-300 hover:bg-gray-700' 
                        : 'text-gray-600 hover:bg-gray-200'
                    }`}
                    title="Modifica nome"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                </div>
              </td>
              <td className={`px-6 py-4 whitespace-nowrap ${
                isDarkMode ? 'text-gray-100' : 'text-gray-900'
              }`}>
                <div className="text-sm font-medium">
                  <button 
                    className={`group inline-flex items-center gap-2 px-3 py-1.5 rounded transition-all duration-200 ${
                      isDarkMode 
                        ? 'text-blue-400 hover:text-blue-300 hover:bg-blue-900/30' 
                        : 'text-blue-600 hover:text-blue-800 hover:bg-blue-50'
                    }`}
                    onClick={() => copyToClipboard(widget.api_key)}
                    title="Clicca per copiare l'ID"
                  >
                    <span>{widget.api_key}</span>
                    {copiedId === widget.api_key ? (
                      <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500" />
                    ) : (
                      <ClipboardDocumentIcon className="h-5 w-5 opacity-0 group-hover:opacity-100 transition-opacity" />
                    )}
                  </button>
                </div>
              </td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}>
                {widget.model_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  widget.status === 'ACTIVE' 
                    ? isDarkMode 
                      ? 'bg-green-900/30 text-green-300' 
                      : 'bg-green-100 text-green-800'
                    : isDarkMode 
                      ? 'bg-yellow-900/30 text-yellow-300' 
                      : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {widget.status}
                </span>
              </td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}>
                {formatDate(widget.created_at, 'dd/MM/yyyy')}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              }`}>
                {widget.last_used ? 
                  formatDate(widget.last_used, 'dd/MM/yyyy HH:mm') : 
                  'Mai utilizzato'}
              </td>
              <td className={`px-6 py-4 whitespace-nowrap text-right text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-gray-500'
              } font-medium`}>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => onEdit(widget)}
                    className={`text-blue-600 hover:text-blue-900 ${
                      isDarkMode ? 'hover:text-blue-400' : ''
                    }`}
                    title="Modifica configurazione completa"
                  >
                    <Cog6ToothIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDelete(widget.id)}
                    className={`text-red-600 hover:text-red-900 ${
                      isDarkMode ? 'hover:text-red-400' : ''
                    }`}
                    title="Elimina"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
} 