import React, { useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

export default function SecurityConfig({ config, onUpdate }) {
  const [newDomain, setNewDomain] = useState('');

  const addDomain = () => {
    if (!newDomain) return;
    
    // Pulisci il dominio
    const cleanDomain = newDomain
      .replace(/^https?:\/\//, '')
      .trim();
      
    if (cleanDomain) {
      const currentDomains = Array.isArray(config.allowed_domains) ? config.allowed_domains : [];
      const updatedDomains = [...currentDomains, cleanDomain];
      console.log('Domini aggiornati:', updatedDomains); // Debug log
      onUpdate({
        allowed_domains: updatedDomains
      });
    }
    setNewDomain('');
  };

  const removeDomain = (domain) => {
    const updatedDomains = config.allowed_domains.filter(d => d !== domain);
    console.log('Domini dopo rimozione:', updatedDomains); // Debug log
    onUpdate({
      allowed_domains: updatedDomains
    });
  };

  return (
    <div className="space-y-6">
      {/* Rate Limiting */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Rate Limit (richieste/minuto)
        </label>
        <div className="mt-1 flex items-center space-x-2">
          <input
            type="number"
            min="1"
            max="1000"
            value={config.rate_limit}
            onChange={(e) => onUpdate({ rate_limit: parseInt(e.target.value) })}
            className="block w-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          <span className="text-sm text-gray-500">richieste al minuto</span>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Limita il numero di richieste che un utente può fare in un minuto
        </p>
      </div>

      {/* Domini Consentiti */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Domini Consentiti
        </label>
        <p className="mt-1 text-sm text-gray-500">
          Lascia vuoto per consentire tutti i domini, o aggiungi domini specifici
        </p>
        
        {/* Input nuovo dominio */}
        <div className="mt-2 flex space-x-2">
          <input
            type="text"
            value={newDomain}
            onChange={(e) => setNewDomain(e.target.value)}
            placeholder="esempio.com"
            className="block flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            onKeyPress={(e) => e.key === 'Enter' && addDomain()}
          />
          <button
            onClick={addDomain}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <PlusIcon className="h-4 w-4" />
          </button>
        </div>

        {/* Lista domini */}
        <div className="mt-3 space-y-2">
          {config.allowed_domains.map((domain) => (
            <div
              key={domain}
              className="flex items-center justify-between px-3 py-2 bg-gray-50 rounded-md"
            >
              <span className="text-sm text-gray-700">{domain}</span>
              <button
                onClick={() => removeDomain(domain)}
                className="text-gray-400 hover:text-red-500"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Note di Sicurezza */}
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Note sulla Sicurezza
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <ul className="list-disc pl-5 space-y-1">
                <li>
                  Il rate limiting aiuta a prevenire l'abuso del servizio
                </li>
                <li>
                  Limitare i domini aumenta la sicurezza ma riduce la portabilità
                </li>
                <li>
                  Considera di aggiungere domini di test e staging oltre a quello di produzione
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 