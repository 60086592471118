import React, { useState, useEffect, useRef } from 'react';
import { TrashIcon as DeleteIcon } from '@heroicons/react/24/outline';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import axios from 'axios';
import DeployPreview from './DeployPreview';
import PlaygroundHeader from './PlaygroundHeader';
import PlaygroundGuide from './PlaygroundGuide';
import { 
  ArrowUpCircleIcon,
  TrashIcon, 
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  PaperAirplaneIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Switch, Dialog } from '@headlessui/react';
import WidgetCustomizer from './components/widget-customizer/WidgetCustomizer';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const DEFAULT_SYSTEM_PROMPT = `Sei un agente di supporto dell'impresa [inserisci sostituendo questo testo con il nome della tua impresa e poi salva il prompt]. 
La tua funzione principale è assistere i clienti in caso di problemi con i nostri prodotti o servizi. 
Dovete ascoltare attivamente, comprendere non solo il contenuto esplicito delle richieste dei clienti, ma anche individuare i segnali più sottili che indicano frustrazione o confusione. 
Rispondere con soluzioni non solo efficaci, ma anche comunicate con calore e comprensione. 
Se un cliente si arrabbia, mantenete la calma e la calma, utilizzando tecniche de-escalation per calmare il cliente. 
Il vostro obiettivo è risolvere i problemi in modo rapido, efficiente e con un tocco personale che faccia sentire i clienti ascoltati e accuditi.`;

const DEFAULT_RAG_PROMPT = `
ISTRUZIONI PER LA GESTIONE DEI DATI:
1. Rispondi SOLO in base ai dati forniti nel contesto
2. Se un'informazione non è presente nei dati, dillo chiaramente
3. Non fare supposizioni o inferenze non supportate dai dati
4. Se i dati mostrano che un item non è disponibile, comunicalo chiaramente
5. Verifica sempre il prezzo e la disponibilità prima di confermare

FORMATO DATI:
I dati sono nel formato: Nome_Pianta, Prezzo_Euro, Disponibilità
`;

const ModelPlayground = ({ token, isDarkMode }) => {
  const [savedModels, setSavedModels] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);
  const [selectedModel, setSelectedModel] = useState('');
  const [currentModelDetails, setCurrentModelDetails] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(true);
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(150);
  const messageEndRef = useRef(null);
  const [systemPrompt, setSystemPrompt] = useState('');
  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const [ragDocuments, setRagDocuments] = useState([]);
  const [selectedRagDocs, setSelectedRagDocs] = useState([]);
  const [useRag, setUseRag] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [fineTunedModels, setFineTunedModels] = useState([]);
  const [baseModels, setBaseModels] = useState([]);
  const [ragPrompt, setRagPrompt] = useState(DEFAULT_RAG_PROMPT);
  const [isEditingRagPrompt, setIsEditingRagPrompt] = useState(false);
  const [isWidgetCustomizerOpen, setIsWidgetCustomizerOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({
    model: '',
    systemPrompt: '',
    temperature: 0.7,
    maxTokens: 150,
    useRag: false,
    ragPrompt: DEFAULT_RAG_PROMPT,
    ragDocuments: [],
    modelDetails: null,
    messages: [],
    activeRagDocuments: [],
    ragDocumentDetails: []
  });
  const [showSourceDebug, setShowSourceDebug] = useState(false);

  useEffect(() => {
    if (token) {
      fetchSavedModels();
      loadRagDocuments();
    }
  }, [token]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (selectedModel) {
      setMessages([]); // Reset chat when model changes
      
      // Cerca nei modelli fine-tuned
      const fineTunedModel = fineTunedModels.find(m => m.fine_tuned_model === selectedModel);
      if (fineTunedModel) {
        setCurrentModelDetails(fineTunedModel);
        setSystemPrompt(fineTunedModel.system_prompt || DEFAULT_SYSTEM_PROMPT);
      } else {
        // Cerca nei modelli base
        const baseModel = baseModels.find(m => m.id === selectedModel);
        if (baseModel) {
          setCurrentModelDetails(baseModel);
          setSystemPrompt(DEFAULT_SYSTEM_PROMPT);
        }
      }
      
      console.log('Selected model changed:', selectedModel);
    }
  }, [selectedModel, fineTunedModels, baseModels]);

  useEffect(() => {
    if (selectedModel) {
      console.log('Selected RAG Docs:', selectedRagDocs);
      console.log('Mapped RAG Doc IDs:', selectedRagDocs.map(doc => doc.id));
      
      setCurrentConfig({
        model: selectedModel,
        systemPrompt,
        temperature,
        maxTokens,
        useRag,
        ragPrompt,
        ragDocuments: selectedRagDocs.map(id => String(id)),
        modelDetails: currentModelDetails,
        messages: messages,
        activeRagDocuments: selectedRagDocs,
        ragDocumentDetails: selectedRagDocs.map(doc => ({
          id: doc,
          type: 'rag'
        }))
      });
    }
  }, [selectedModel, systemPrompt, temperature, maxTokens, useRag, 
      ragPrompt, selectedRagDocs, currentModelDetails, messages]);

  const fetchSavedModels = async () => {
    try {
      console.log('Fetching models...');
      const response = await axios.get(`${API_BASE_URL}/playground/models`, {  
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data) {
        console.log('Models fetched:', response.data);
        setFineTunedModels(response.data.fine_tuned_models);
        setBaseModels(response.data.base_models);
        
        // Se non c'è un modello selezionato, seleziona il primo disponibile
        if (!selectedModel) {
          const firstFineTuned = response.data.fine_tuned_models[0]?.fine_tuned_model;
          const firstBase = response.data.base_models[0]?.id;
          setSelectedModel(firstFineTuned || firstBase || '');
        }
      }
    } catch (error) {
      console.error('Error fetching models:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore nel caricamento dei modelli: ${error.response?.data?.detail || error.message}`
      }]);
    }
  };

  const handleModelChange = async (event) => {
    const newModelId = event.target.value;
    console.log('Changing model to:', newModelId);
    setSelectedModel(newModelId);
    setMessages([]); // Reset chat
    
    try {
      // Cerca prima nei modelli fine-tuned
      const fineTunedModel = fineTunedModels.find(m => m.fine_tuned_model === newModelId);
      
      if (fineTunedModel) {
        setSystemPrompt(fineTunedModel.system_prompt || DEFAULT_SYSTEM_PROMPT);
      } else {
        // Se è un modello base, usa il prompt di sistema predefinito
        const baseModel = baseModels.find(m => m.id === newModelId);
        if (baseModel) {
          setSystemPrompt(DEFAULT_SYSTEM_PROMPT);
        }
      }
      
      setMessages([{
        role: 'system',
        content: `Modello selezionato: ${newModelId}`
      }]);
    } catch (error) {
      console.error('Error changing model:', error);
      setMessages([{
        role: 'system',
        content: `Errore nel cambio del modello: ${error.message}`
      }]);
    }
  };
    
    
  // Aggiungi queste funzioni nel componente
const handleDeleteModel = async (modelId) => {
  setModelToDelete(modelId);
  setIsDeleteModalOpen(true);
};

const confirmDelete = async () => {
  try {
    await axios.delete(
      `${API_BASE_URL}/playground/models/${encodeURIComponent(modelToDelete)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );

    // Aggiorna la lista dei modelli
    await fetchSavedModels();
    
    // Se il modello eliminato era quello selezionato, resetta la selezione
    if (selectedModel === modelToDelete) {
      setSelectedModel('');
      setMessages([]);
    }

    setMessages(prev => [...prev, {
      role: 'system',
      content: 'Modello eliminato con successo.',
      timestamp: new Date().toISOString()
    }]);

  } catch (error) {
    console.error('Errore nell\'eliminazione del modello:', error);
    setMessages(prev => [...prev, {
      role: 'system',
      content: `Errore nell'eliminazione del modello: ${error.response?.data?.detail || error.message}`,
      timestamp: new Date().toISOString()
    }]);
  } finally {
    setIsDeleteModalOpen(false);
    setModelToDelete(null);
  }
};    
    
  const handleSendMessage = async () => {
    if (!inputMessage.trim() || !selectedModel) return;

    const newMessage = {
      content: inputMessage,
      role: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      // Filtra la cronologia dei messaggi per includere solo role e content
      const filteredHistory = messages.map(msg => ({
        role: msg.role,
        content: msg.content
      }));

      const requestData = {
        message: inputMessage,
        model: selectedModel,
        temperature,
        max_tokens: maxTokens,
        system_prompt: systemPrompt,
        use_rag: useRag,
        rag_docs: selectedRagDocs,
        rag_prompt: useRag ? ragPrompt : null,
        message_history: filteredHistory
      };

      const response = await axios.post(
        `${API_BASE_URL}/playground/chat`,
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.data) {
        const botMessage = {
          content: response.data.response,
          role: 'assistant',
          model: selectedModel,
          timestamp: new Date(),
          sources: response.data.sources
        };
        setMessages(prev => [...prev, botMessage]);
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore: ${err.response?.data?.detail || err.message}`,
        timestamp: new Date()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    console.log('Clearing chat');
    setMessages([{
      role: 'system',
      content: `Chat pulita. Modello attivo: ${selectedModel}`,
      timestamp: new Date().toISOString()
    }]);
  };

  const handleUpdateSystemPrompt = async () => {
    if (!selectedModel || !systemPrompt.trim()) return;
    
    try {
      console.log('Updating system prompt for model:', selectedModel);
      await axios.put(
        `${API_BASE_URL}/playground/models/${encodeURIComponent(selectedModel)}/system-prompt`,
        { 
          system_prompt: systemPrompt 
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setIsEditingPrompt(false);
      setMessages(prev => [...prev, {
        role: 'system',
        content: 'Prompt di sistema aggiornato con successo.',
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error updating system prompt:', error);
      setMessages(prev => [...prev, {
        role: 'system',
        content: `Errore nell'aggiornamento del prompt di sistema: ${error.response?.data?.detail || error.message}`,
        timestamp: new Date().toISOString()
      }]);
    }
  };

  const loadRagDocuments = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/rag/documents`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      if (response.data) {
        setRagDocuments(response.data);
      }
    } catch (err) {
      console.error('Error loading RAG documents:', err);
    }
  };

  // Aggiungi la sezione RAG nella sidebar
  const RagSection = () => {
    return (
      <div className="space-y-2">
        {ragDocuments.map((doc) => (
          <div 
            key={doc.id}
            className={`p-3 rounded-lg ${
              isDarkMode 
                ? 'bg-gray-700/50 hover:bg-gray-600' 
                : 'bg-gray-50 hover:bg-gray-100'
            } transition-colors duration-200`}
          >
            <div className="flex items-center space-x-3">
              <input
                type="checkbox"
                id={`doc-${doc.id}`}
                checked={selectedRagDocs.includes(doc.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRagDocs([...selectedRagDocs, doc.id]);
                  } else {
                    setSelectedRagDocs(selectedRagDocs.filter(id => id !== doc.id));
                  }
                }}
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <div className="flex-1">
                <label htmlFor={`doc-${doc.id}`} className="cursor-pointer block">
                  <div className={`text-sm font-medium ${
                    isDarkMode ? 'text-gray-200' : 'text-gray-900'
                  }`}>
                    {doc.file_name}
                  </div>
                  <div className="text-xs text-gray-400 mt-1">
                    {new Date(doc.created_at).toLocaleDateString()}
                  </div>
                </label>
              </div>
              <span className={`text-xs px-2 py-1 rounded-full ${
                doc.status === 'completed'
                  ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
                  : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300'
              }`}>
                {doc.status === 'completed' ? 'Elaborato' : 'In elaborazione'}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const ModelSelection = () => (
    <div className="space-y-2">
      <div className="flex justify-between items-center">
        <label className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
          Seleziona Modello
        </label>
        {selectedModel && fineTunedModels.find(m => m.fine_tuned_model === selectedModel) && (
          <button
            onClick={() => handleDeleteModel(selectedModel)}
            className="p-1 text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors duration-200"
            title="Elimina modello"
          >
            <DeleteIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      <select
        value={selectedModel}
        onChange={handleModelChange}
        className={`w-full p-2 rounded-lg border ${
          isDarkMode 
            ? 'bg-gray-700 border-gray-600 text-white' 
            : 'bg-white border-gray-300'
        }`}
      >
        <option value="">Seleziona un modello</option>
        
        {/* Modelli Base */}
        {baseModels.length > 0 && (
          <optgroup label="Modelli Base">
            {baseModels.map(model => (
              <option key={model.id} value={model.id}>
                {model.display_name || model.id}
              </option>
            ))}
          </optgroup>
        )}
        
        {/* Modelli Fine-tuned */}
        {fineTunedModels.length > 0 && (
          <optgroup label="Modelli Fine-tuned">
            {fineTunedModels.map(model => (
              <option key={model.id} value={model.fine_tuned_model}>
                {model.fine_tuned_model}
              </option>
            ))}
          </optgroup>
        )}
      </select>
    </div>
  );

  // Componente per le impostazioni RAG
  const RagSettings = () => {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <label className={`text-sm font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}>
            Knowledge Base
          </label>
          <div className="flex items-center bg-gray-700 rounded-md p-1">
            <Switch
              checked={useRag}
              onChange={setUseRag}
              className={`${
                useRag ? 'bg-blue-600' : 'bg-gray-500'
              } relative inline-flex h-5 w-9 items-center rounded-full transition-colors`}
            >
              <span className={`${
                useRag ? 'translate-x-4' : 'translate-x-1'
              } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}/>
            </Switch>
          </div>
        </div>

        {useRag && (
          <>
            <div className="flex items-center justify-between">
              <label className={`text-sm font-medium ${
                isDarkMode ? 'text-gray-200' : 'text-gray-700'
              }`}>
                Mostra Dettagli Fonti
              </label>
              <div className="flex items-center bg-gray-700 rounded-md p-1">
                <Switch
                  checked={showSourceDebug}
                  onChange={setShowSourceDebug}
                  className={`${
                    showSourceDebug ? 'bg-blue-600' : 'bg-gray-500'
                  } relative inline-flex h-5 w-9 items-center rounded-full transition-colors`}
                >
                  <span className={`${
                    showSourceDebug ? 'translate-x-4' : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}/>
                </Switch>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <label className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                  RAG Prompt
                </label>
                <button
                  onClick={() => {
                    if (isEditingRagPrompt) {
                      setIsEditingRagPrompt(false);
                    } else {
                      setIsEditingRagPrompt(true);
                    }
                  }}
                  className="text-sm text-blue-500"
                >
                  {isEditingRagPrompt ? 'Salva' : 'Modifica'}
                </button>
              </div>
              {isEditingRagPrompt ? (
                <textarea
                  value={ragPrompt}
                  onChange={(e) => setRagPrompt(e.target.value)}
                  className={`w-full p-3 rounded-lg border ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white' 
                      : 'bg-white border-gray-300'
                  }`}
                  rows={8}
                  placeholder="Inserisci le istruzioni per il RAG..."
                />
              ) : (
                <div className={`p-3 rounded-lg text-sm ${
                  isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-50 text-gray-600'
                }`}>
                  {ragPrompt}
                </div>
              )}
            </div>
            
            <RagSection />
          </>
        )}
      </div>
    );
  };

  const handleWidgetOpen = () => {
    if (!selectedRagDocs.length && useRag) {
        toast.error('Seleziona almeno un documento RAG');
        return;
    }
    
    console.log('Inviando documenti RAG:', selectedRagDocs.map(String));
    setIsWidgetCustomizerOpen(true);
  };

  const handleWidgetSave = async (widgetConfig, keepOpen = false) => {
    try {
        if (!token) {
            console.error('Token mancante');
            toast.error('Errore di autenticazione. Effettua nuovamente il login.');
            return;
        }

        const response = await axios.post(
            `${API_BASE_URL}/widget/create-from-playground`,
            widgetConfig,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        if (response.data) {
            toast.success('Widget salvato con successo!');
            if (!keepOpen) {
                setIsWidgetCustomizerOpen(false);
            }
            return response.data;
        }
    } catch (error) {
        console.error('Errore nel salvataggio del widget:', error);
        
        if (error.response?.status === 401) {
            toast.error('Sessione scaduta. Effettua nuovamente il login.');
            return;
        }
        
        toast.error('Errore nel salvataggio del widget');
        throw error;
    }
};

  // Rendering del componente
    return (
      <>
        <Toaster position="top-right" />
        <div className={`h-screen flex flex-col ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
          {/* Header senza bordi arrotondati */}
          <div className={`w-full ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <div className="max-w-3xl mx-auto px-4 py-3 flex items-center justify-between">
              <h1 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Test e Configurazione
              </h1>
              <button
                onClick={() => setShowSettings(!showSettings)}
                className={`p-2 hover:bg-opacity-10 hover:bg-gray-500`}
              >
                <Cog6ToothIcon className={`h-6 w-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 relative">
            {/* Chat Area principale */}
            <div className="absolute inset-0 flex flex-col">
              {messages.length === 0 ? (
                // Form centrato quando non ci sono messaggi
                <div className="h-full flex items-center justify-center">
                  <div className="w-full max-w-3xl px-4">
                    <div className="text-center mb-8">
                      <h2 className={`text-2xl font-semibold mb-2 ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        How can I help you today?
                      </h2>
                    </div>
                    <div className="flex items-center space-x-4">
                      <input
                        type="text"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        placeholder="Scrivi un messaggio..."
                        className={`flex-1 p-4 rounded-xl border ${
                          isDarkMode 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'bg-white border-gray-300'
                        }`}
                        disabled={isLoading || !selectedModel}
                      />
                      <button
                        onClick={handleSendMessage}
                        disabled={isLoading || !inputMessage.trim() || !selectedModel}
                        className="p-4 rounded-xl bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50"
                      >
                        <PaperAirplaneIcon className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                // Layout quando ci sono messaggi
                <div className="flex flex-col h-full">
                  {/* Area messaggi con scroll e padding-bottom per evitare che i messaggi finiscano sotto il form */}
                  <div className="flex-1 overflow-y-auto pb-24">
                    <div className="max-w-3xl mx-auto px-4 py-6 flex flex-col space-y-6">
                      {messages.map((message, index) => (
                        <div
                          key={index}
                          className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
                        >
                          <div className={`max-w-[85%] p-4 rounded-2xl ${
                            message.role === 'user'
                              ? 'bg-blue-600 text-white hover:bg-blue-700'
                              : message.role === 'system'
                              ? `${isDarkMode ? 'bg-yellow-600/20 text-yellow-200' : 'bg-yellow-50 text-yellow-800'}`
                              : isDarkMode
                              ? 'bg-gray-700 text-white'
                              : 'bg-gray-100 text-gray-900'
                          }`}>
                            <div className={`prose max-w-none ${
                              message.role === 'user' 
                                ? 'text-white'
                                : isDarkMode 
                                ? 'prose-invert' 
                                : ''
                            }`}>
                              {message.content}
                            </div>
                            {showSourceDebug && message.sources && (
                              <div className="mt-3 space-y-2">
                                <div className="text-sm font-medium opacity-75">Fonti:</div>
                                {message.sources.map((source, idx) => (
                                  <div key={idx} className={`p-2 rounded-lg text-sm ${
                                    isDarkMode ? 'bg-gray-600/50' : 'bg-gray-200/50'
                                  }`}>
                                    <div>{source.text}</div>
                                    <div className="text-xs opacity-75 mt-1">
                                      Similarità: {(source.similarity * 100).toFixed(1)}%
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      {isLoading && (
                        <div className="flex justify-start mb-6">
                          <div className={`p-4 rounded-2xl ${
                            isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                          }`}>
                            <div className="flex space-x-2">
                              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce" />
                              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-100" />
                              <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce delay-200" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Form chat in basso - fixed position */}
                  <div className={`fixed bottom-0 left-0 right-0 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} border-t ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}>
                    <div className="max-w-3xl mx-auto px-4 py-4">
                      <div className="flex items-center space-x-4">
                        <input
                          type="text"
                          value={inputMessage}
                          onChange={(e) => setInputMessage(e.target.value)}
                          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                          placeholder="Scrivi un messaggio..."
                          className={`flex-1 p-4 rounded-xl border ${
                            isDarkMode 
                              ? 'bg-gray-700 border-gray-600 text-white' 
                              : 'bg-white border-gray-300'
                          }`}
                          disabled={isLoading || !selectedModel}
                        />
                        <button
                          onClick={handleSendMessage}
                          disabled={isLoading || !inputMessage.trim() || !selectedModel}
                          className="p-4 rounded-xl bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50"
                        >
                          <PaperAirplaneIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Settings Sidebar */}
            <div className={`
              fixed inset-y-0 right-0 w-96 transform transition-transform duration-300
              ${showSettings ? 'translate-x-0' : 'translate-x-full'}
              ${isDarkMode ? 'bg-gray-800 border-l border-gray-700' : 'bg-white border-l border-gray-200'}
            `}>
              <div className="h-full flex flex-col">
                {/* Header Sidebar */}
                <div className="p-4 border-b border-gray-700 flex justify-between items-center">
                  <h2 className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    Impostazioni
                  </h2>
                  <button onClick={() => setShowSettings(false)}>
                    <XMarkIcon className="h-6 w-6 text-gray-500" />
                  </button>
                </div>

                {/* Contenuto Sidebar scrollabile */}
                <div className="flex-1 overflow-y-auto p-4 space-y-6">
                  {/* Link Guida in cima */}
                  <button
                    onClick={() => setShowGuide(true)}
                    className={`w-full p-3 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 dark:hover:bg-blue-900/20 transition-colors duration-200`}
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5 inline mr-2" />
                    Mostra Guida
                  </button>

                  {/* Model Selection */}
                  <ModelSelection />

                  {/* System Prompt */}
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <label className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
                        System Prompt
                      </label>
                      <button
                        onClick={() => setIsEditingPrompt(!isEditingPrompt)}
                        className="text-sm text-blue-500"
                      >
                        {isEditingPrompt ? 'Salva' : 'Modifica'}
                      </button>
                    </div>
                    {isEditingPrompt ? (
                      <textarea
                        value={systemPrompt}
                        onChange={(e) => setSystemPrompt(e.target.value)}
                        className={`w-full p-3 rounded-lg border ${
                          isDarkMode 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'bg-white border-gray-300'
                        }`}
                        rows={6}
                      />
                    ) : (
                      <div className={`p-3 rounded-lg text-sm ${
                        isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-50 text-gray-600'
                      }`}>
                        {systemPrompt}
                      </div>
                    )}
                  </div>

                  {/* Model Settings */}
                  <div className="space-y-4">
                    <div>
                      <label className={`block text-sm font-medium mb-2 ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-700'
                      }`}>
                        Temperature: {temperature}
                      </label>
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={temperature}
                        onChange={(e) => setTemperature(parseFloat(e.target.value))}
                        className="w-full"
                      />
                    </div>
                    <div>
                      <label className={`block text-sm font-medium mb-2 ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-700'
                      }`}>
                        Max Tokens: {maxTokens}
                      </label>
                      <input
                        type="range"
                        min="50"
                        max="500"
                        step="50"
                        value={maxTokens}
                        onChange={(e) => setMaxTokens(parseInt(e.target.value))}
                        className="w-full"
                      />
                    </div>
                  </div>

                  <RagSettings />
                </div>

                {/* Footer Sidebar */}
                <div className="flex justify-between items-center p-4 border-t">
                  <button
                    onClick={clearChat}
                    className="flex items-center px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Pulisci Chat
                  </button>

                  {/* Nuovo bottone Crea Widget */}
                  <button
                    onClick={handleWidgetOpen}
                    className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition-colors"
                  >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Crea Widget
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Guide Modal */}
          {showGuide && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className={`max-w-2xl w-full rounded-xl p-6 ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}>
                <PlaygroundGuide isDarkMode={isDarkMode} />
                <button
                  onClick={() => setShowGuide(false)}
                  className="mt-4 w-full py-2 bg-blue-600 text-white rounded-lg"
                >
                  Chiudi
                </button>
              </div>
            </div>
          )}

          {/* DeleteConfirmationModal */}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={confirmDelete}
            modelId={modelToDelete}
          />

          {/* Dialog per il Widget Customizer */}
          {isWidgetCustomizerOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                <div className={`w-full max-w-6xl h-[90vh] rounded-xl ${
                    isDarkMode ? 'bg-gray-800' : 'bg-white'
                }`}>
                    <WidgetCustomizer
                        playgroundConfig={{
                            model: selectedModel,
                            systemPrompt,
                            temperature,
                            maxTokens,
                            useRag,
                            ragPrompt: ragPrompt,
                            activeRagDocuments: selectedRagDocs
                        }}
                        onSave={handleWidgetSave}
                        onClose={() => setIsWidgetCustomizerOpen(false)}
                        token={token}
                    />
                </div>
            </div>
          )}
        </div>
      </>
    );
    };

export default ModelPlayground;