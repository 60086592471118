import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

export default function RAGConfig({ config, onUpdate, token, isDarkMode = false }) {
  // Sistema di logging migliorato
  const logState = (action, data) => {
    console.log(`[RAGConfig][${action}]:`, data);
  };

  // Stati con inizializzazione migliorata
  const [ragDocuments, setRagDocuments] = useState([]);
  const [selectedRagDocs, setSelectedRagDocs] = useState(() => {
    // Controlliamo tutte le possibili fonti di documenti nella configurazione
    const possibleDocs = [
      ...(config.activeRagDocuments || []),
      ...(config.ragDocuments || []),
      ...(config.rag_document_ids || [])
    ];

    // Normalizziamo gli ID e rimuoviamo i duplicati
    const uniqueDocs = [...new Set(possibleDocs.map(doc => 
      typeof doc === 'object' ? String(doc.id) : String(doc)
    ))];

    logState('Initial Selected Docs', uniqueDocs);
    return uniqueDocs;
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Funzione per normalizzare gli ID dei documenti
  const normalizeDocumentId = (id) => {
    if (typeof id === 'object' && id.id) return String(id.id);
    return String(id);
  };

  // Caricamento documenti con gestione errori migliorata
  const loadRagDocuments = async () => {
    try {
      setIsLoading(true);
      logState('Loading Documents', { token: !!token });
      const response = await axios.get(
        `${API_BASE_URL}/rag/documents`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data) {
        logState('Documents Loaded', response.data);
        setRagDocuments(response.data);

        // Verifichiamo che i documenti selezionati esistano nei documenti caricati
        if (selectedRagDocs.length > 0) {
          const validDocs = selectedRagDocs.filter(id => 
            response.data.some(doc => String(doc.id) === String(id))
          );

          if (validDocs.length !== selectedRagDocs.length) {
            logState('Updating Valid Selections', validDocs);
            setSelectedRagDocs(validDocs);
            onUpdate({ rag_document_ids: validDocs });
          }
        }
      }
    } catch (error) {
      logState('Loading Error', error);
      setError(error.message || 'Errore nel caricamento dei documenti');
    } finally {
      setIsLoading(false);
    }
  };

  // Effetto per il caricamento iniziale
  useEffect(() => {
    if (token) {
      loadRagDocuments();
    }
  }, [token]);

  // Effetto per la sincronizzazione dello stato
  useEffect(() => {
    // Verifichiamo se c'è un cambiamento significativo nella configurazione
    const newUseRag = config.use_rag;
    const newDocs = config.activeRagDocuments || config.ragDocuments || config.rag_document_ids || [];
    const newDocsNormalized = newDocs.map(doc => 
      typeof doc === 'object' ? String(doc.id) : String(doc)
    );

    const hasStateChanged = 
      newUseRag !== config.use_rag ||
      JSON.stringify(newDocsNormalized.sort()) !== JSON.stringify(selectedRagDocs.sort());

    if (hasStateChanged) {
      logState('State Change Detected', {
        newUseRag,
        newDocs: newDocsNormalized,
        previousSelection: selectedRagDocs
      });

      if (newUseRag) {
        setSelectedRagDocs(newDocsNormalized);
      } else {
        setSelectedRagDocs([]);
      }
      onUpdate({
        use_rag: newUseRag,
        rag_document_ids: newUseRag ? newDocsNormalized : []
      });
    }
  }, [config]);

  // Modifica nell'effetto di inizializzazione
  useEffect(() => {
    if (config.rag_document_ids) {
      const normalizedIds = config.rag_document_ids.map(id => 
        typeof id === 'number' ? String(id) : id
      );
      setSelectedRagDocs(normalizedIds);
    }
  }, [config.rag_document_ids]);

  // Gestione della selezione dei documenti
  const handleDocumentSelect = (docId) => {
    const normalizedId = String(docId);
    const newSelection = selectedRagDocs.includes(normalizedId)
      ? selectedRagDocs.filter(id => id !== normalizedId)
      : [...selectedRagDocs, normalizedId];

    onUpdate({ 
      rag_document_ids: newSelection,
      activeRagDocuments: newSelection.map(id => ({ id })) 
    });
  };

  // Gestione del toggle RAG
  const handleRagToggle = (enabled) => {
    logState('RAG Toggle', { enabled, currentDocs: selectedRagDocs });

    // Notifichiamo il componente padre
    onUpdate({
      use_rag: enabled,
      rag_document_ids: enabled ? selectedRagDocs : []
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className={`text-sm font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-700'
          }`}>
            Knowledge Base
          </h3>
          <p className={`text-sm ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            Permette al chatbot di utilizzare i documenti per rispondere
          </p>
        </div>
        <Switch
          checked={config.use_rag}
          onChange={handleRagToggle}
          className={`${
            config.use_rag ? 'bg-blue-600' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Abilita RAG</span>
          <span
            className={`${
              config.use_rag ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>

      {config.use_rag && (
        <div className="space-y-4">
          <div className="mt-2 space-y-2">
            {isLoading ? (
              <div className="flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
              </div>
            ) : error ? (
              <div className="text-red-500 p-4 text-center">{error}</div>
            ) : ragDocuments.length === 0 ? (
              <div className="text-gray-500 p-4 text-center">
                Nessun documento disponibile
              </div>
            ) : (
              ragDocuments.map((doc) => (
                <div 
                  key={doc.id}
                  className={`p-3 rounded-lg ${
                    isDarkMode 
                      ? 'bg-gray-700/50 hover:bg-gray-600' 
                      : 'bg-gray-50 hover:bg-gray-100'
                  } transition-colors duration-200`}
                >
                  <div className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      id={`doc-${doc.id}`}
                      checked={selectedRagDocs.includes(normalizeDocumentId(doc.id))}
                      onChange={() => handleDocumentSelect(doc.id)}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <div className="flex-1">
                      <label htmlFor={`doc-${doc.id}`} className="cursor-pointer block">
                        <div className={`text-sm font-medium ${
                          isDarkMode ? 'text-gray-200' : 'text-gray-900'
                        }`}>
                          {doc.file_name}
                        </div>
                        <div className="text-xs text-gray-400 mt-1">
                          {new Date(doc.created_at).toLocaleDateString()}
                        </div>
                      </label>
                    </div>
                    <span className={`text-xs px-2 py-1 rounded-full ${
                      doc.status === 'completed'
                        ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300'
                        : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300'
                    }`}>
                      {doc.status === 'completed' ? 'Elaborato' : 'In elaborazione'}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
}