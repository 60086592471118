import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import ModelConfig from './tabs/ModelConfig';
import UIConfig from './tabs/UIConfig';
import SecurityConfig from './tabs/SecurityConfig';
import RAGConfig from './tabs/RAGConfig';
import WidgetPreview from './preview/WidgetPreview';
import CodePreview from './preview/CodePreview';
import { toast } from 'react-hot-toast';

// Utility per gestire le classi CSS in modo condizionale
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Funzione di normalizzazione degli ID dei documenti RAG
const normalizeRagDocuments = (docs) => {
  if (!docs) return [];
  if (Array.isArray(docs)) {
    return docs.map(doc => {
      if (typeof doc === 'object' && doc.id) {
        return String(doc.id);
      }
      return String(doc);
    });
  }
  return [];
};

export default function WidgetCustomizer({ playgroundConfig, onSave, onClose, token, existingWidget }) {
  // Sistema di logging avanzato per debugging
  const logState = (action, data) => {
    console.log(`[WidgetCustomizer][${action}]`, data);
  };

  logState('Initial Config', playgroundConfig);

  // Configurazione di default migliorata
  const defaultConfig = {
    // Aggiungi api_key alla configurazione di default
    api_key: playgroundConfig?.api_key || '',
    // Configurazioni del modello
    model_name: playgroundConfig?.model || '',
    system_prompt: playgroundConfig?.systemPrompt || '',
    temperature: playgroundConfig?.temperature || 0.7,
    max_tokens: playgroundConfig?.maxTokens || 150,
    
    // Configurazioni RAG
    use_rag: playgroundConfig?.useRag || false,
    rag_prompt: playgroundConfig?.ragPrompt || '',
    rag_document_ids: normalizeRagDocuments(playgroundConfig?.activeRagDocuments),
    
    // Configurazioni UI
    name: 'Widget Personalizzato',
    position: 'bottom-right',
    theme: {
      primary_color: '#2563eb',
      background_color: '#ffffff',
      text_color: '#000000',
      font_family: 'system-ui'
    },
    custom_css: '',
    welcome_message: 'Ciao! Come posso aiutarti?',
    placeholder_text: 'Scrivi un messaggio...',
    allowed_domains: [],
    rate_limit: 60
  };

  // Stati principali
  const [activeTab, setActiveTab] = useState('ui');
  const [widgetConfig, setWidgetConfig] = useState(() => {
    if (!playgroundConfig && !existingWidget) return defaultConfig;

    const baseConfig = existingWidget ? {
      ...existingWidget,
      api_key: existingWidget.api_key // Assicurati di includere l'api_key
    } : playgroundConfig;

    const normalizedDocIds = normalizeRagDocuments(
      baseConfig.useRag ? baseConfig.activeRagDocuments : []
    );

    logState('Initializing Widget Config', { 
      useRag: baseConfig.useRag,
      normalizedDocIds 
    });

    return {
      ...defaultConfig,
      ...baseConfig,
      model_name: baseConfig.model || defaultConfig.model_name,
      system_prompt: baseConfig.systemPrompt || defaultConfig.system_prompt,
      temperature: baseConfig.temperature || defaultConfig.temperature,
      max_tokens: baseConfig.maxTokens || defaultConfig.max_tokens,
      use_rag: baseConfig.useRag || defaultConfig.use_rag,
      rag_prompt: baseConfig.ragPrompt || defaultConfig.rag_prompt,
      rag_document_ids: normalizedDocIds,
    };
  });

  // Effetto per la sincronizzazione della configurazione
  useEffect(() => {
    if (playgroundConfig) {
      logState('Updating Config from Playground', playgroundConfig);

      const normalizedDocIds = normalizeRagDocuments(
        playgroundConfig.useRag ? playgroundConfig.activeRagDocuments : []
      );

      logState('Normalized Document IDs', normalizedDocIds);

      setWidgetConfig(prev => {
        const newConfig = {
          ...prev,
          model_name: playgroundConfig.model || prev.model_name,
          system_prompt: playgroundConfig.systemPrompt || prev.system_prompt,
          temperature: playgroundConfig.temperature || prev.temperature,
          max_tokens: playgroundConfig.maxTokens || prev.max_tokens,
          use_rag: playgroundConfig.useRag,
          rag_prompt: playgroundConfig.ragPrompt || prev.rag_prompt,
          rag_document_ids: normalizedDocIds,
        };

        logState('New Widget Config', newConfig);
        return newConfig;
      });
    }
  }, [playgroundConfig]);

  // Aggiungi questo effetto per sincronizzare i documenti RAG
  useEffect(() => {
    if (playgroundConfig?.activeRagDocuments) {
      const normalizedIds = normalizeRagDocuments(playgroundConfig.activeRagDocuments);
      setWidgetConfig(prev => ({
        ...prev,
        rag_document_ids: normalizedIds,
        use_rag: playgroundConfig.useRag
      }));
    }
  }, [playgroundConfig?.activeRagDocuments, playgroundConfig?.useRag]);

  // Aggiungiamo uno stato per tracciare se il widget è stato salvato
  const [isSaved, setIsSaved] = useState(false);

  // Handler per gli aggiornamenti della configurazione
  const handleConfigUpdate = (values) => {
    logState('Config Update Requested', values);

    // Gestione speciale per i domini
    if ('allowed_domains' in values) {
        console.log('Aggiornamento domini:', values.allowed_domains);
        values.allowed_domains = Array.isArray(values.allowed_domains) ? 
            values.allowed_domains.filter(Boolean) : [];
    }

    // Gestione speciale per gli aggiornamenti RAG
    if ('use_rag' in values) {
      values = {
        ...values,
        rag_document_ids: values.use_rag ? widgetConfig.rag_document_ids : []
      };
    }

    // Normalizzazione degli ID dei documenti se presenti
    if ('rag_document_ids' in values) {
      values.rag_document_ids = normalizeRagDocuments(values.rag_document_ids);
    }

    // Validazione della posizione se presente
    if (values.position) {
      const validPositions = ['bottom-right', 'bottom-left', 'top-right', 'top-left'];
      const normalized = values.position.toLowerCase().replace('_', '-');
      values.position = validPositions.includes(normalized) ? normalized : 'bottom-right';
    }

    setWidgetConfig(prev => {
      const newConfig = { ...prev, ...values };
      logState('New Config After Update', newConfig);
      return newConfig;
    });
  };

  // Tabs configuration
  const tabs = [
    { name: 'UI', component: UIConfig },
    { name: 'RAG', component: RAGConfig },
    { name: 'Sicurezza', component: SecurityConfig }
  ];

  // Validazione della configurazione
  if (!playgroundConfig || typeof playgroundConfig !== 'object' || !playgroundConfig.model) {
    return (
      <div className="p-6 text-center">
        <div className="text-red-600 mb-4">
          Configurazione non valida. Seleziona un modello e riprova.
        </div>
        <div className="text-sm text-gray-500">
          Assicurati di aver selezionato un modello prima di creare il widget.
        </div>
      </div>
    );
  }

  // Modifica la funzione di salvataggio
  const handleSave = async () => {
    const configToSave = {
      ...widgetConfig,
      // Campi obbligatori per l'update
      status: 'ACTIVE',
      allowed_domains: widgetConfig.allowed_domains || [],
      rag_document_ids: normalizeRagDocuments(widgetConfig.rag_document_ids)
    }

    try {
      await onSave(configToSave)
      setIsSaved(true)
      // Non chiudere il modal dopo il salvataggio
    } catch (error) {
      console.error('Errore nel salvataggio:', error)
      toast.error('Errore durante il salvataggio delle modifiche')
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-50">
      {/* Configurazione ereditata */}
      <div className="bg-gray-50 p-4 rounded-lg mb-4">
        <h3 className="font-semibold mb-2">Configurazione ereditata dal Playground:</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="font-medium text-sm mb-2">Modello e Parametri</h4>
            <ul className="text-sm space-y-1 text-gray-600">
              <li><span className="font-medium">Modello:</span> {playgroundConfig.model}</li>
              <li><span className="font-medium">Temperature:</span> {playgroundConfig.temperature}</li>
              <li><span className="font-medium">Max Tokens:</span> {playgroundConfig.maxTokens}</li>
            </ul>
          </div>
          <div>
            <h4 className="font-medium text-sm mb-2">Configurazione RAG</h4>
            <ul className="text-sm space-y-1 text-gray-600">
              <li>
                <span className="font-medium">RAG:</span> 
                {playgroundConfig.useRag ? 'Attivo' : 'Non attivo'}
              </li>
              <li>
                <span className="font-medium">Documenti:</span> 
                {playgroundConfig.activeRagDocuments?.length || 0}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Tabs di configurazione */}
      <div className="flex-1 flex flex-col lg:flex-row gap-6 p-4 overflow-y-auto">
        <div className="lg:w-1/2">
          <Tab.Group onChange={setActiveTab}>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {tabs.map((tab) => (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow text-blue-700'
                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                  {tab.name}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-6">
              {tabs.map((tab, idx) => (
                <Tab.Panel
                  key={idx}
                  className="rounded-xl bg-white p-3"
                >
                  <tab.component 
                    config={widgetConfig}
                    onUpdate={handleConfigUpdate}
                    token={token}
                    isRagEnabled={widgetConfig.use_rag}
                    ragDocumentIds={widgetConfig.rag_document_ids}
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>

        {/* Preview */}
        <div className="lg:w-1/2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-lg font-semibold mb-4">Anteprima Widget</h3>
            <WidgetPreview config={widgetConfig} />
          </div>
          
          <div className="bg-white rounded-lg shadow-sm p-4">
            <h3 className="text-lg font-semibold mb-4">Codice di Integrazione</h3>
            <CodePreview config={widgetConfig} />
          </div>
        </div>
      </div>

      {/* Footer modificato con due bottoni */}
      <div className="border-t bg-white p-4 flex justify-between items-center">
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-600 hover:text-gray-800 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
        >
          Chiudi
        </button>
        <button
          onClick={handleSave}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          {isSaved ? 'Aggiorna Widget' : 'Salva Widget'}
        </button>
      </div>

      {/* Messaggio di successo quando il widget viene salvato */}
      {isSaved && (
        <div className="absolute bottom-20 right-4 bg-green-50 border-l-4 border-green-500 p-4 rounded shadow-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">
                Widget salvato con successo! Puoi continuare a modificare o chiudere.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}