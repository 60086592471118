import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';
import { useAuth } from '../../contexts/AuthContext';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

const validateUrl = (url) => {
  // Accetta URL con o senza protocollo
  const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?].*)?$/;
  return urlPattern.test(url);
};

const WebsiteAnalyzer = ({ onDatasetGenerated }) => {
  const [url, setUrl] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [maxPages, setMaxPages] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [dataset, setDataset] = useState(null);
  const { getAccessToken, refreshToken } = useAuth();
  const [includePatterns, setIncludePatterns] = useState('');
  const [excludePatterns, setExcludePatterns] = useState('');

  // Nuovi stati per la gestione dei record
  const [editedData, setEditedData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showActions, setShowActions] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showErrorsOnly, setShowErrorsOnly] = useState(false);

  const handleAnalyze = async () => {
    try {
      setIsLoading(true);
      setError('');
      setDataset(null);

      if (!validateUrl(url)) {
        setError('URL non valido');
        setIsLoading(false);
        return;
      }

      // Converti le stringhe di pattern in array, rimuovendo spazi vuoti
      const includeArray = includePatterns
        .split(',')
        .map(p => p.trim())
        .filter(p => p);
      
      const excludeArray = excludePatterns
        .split(',')
        .map(p => p.trim())
        .filter(p => p);

      const response = await axiosInstance.post(
        `/scraper/analyze-website`,
        {
          url: url.trim(),
          business_type: businessType.trim(),
          max_pages: parseInt(maxPages),
          include_patterns: includeArray,
          exclude_patterns: excludeArray
        }
      );

      if (response.data.status === 'success') {
        setDataset(response.data.data);
        onDatasetGenerated(response.data.data);
      } else {
        setError(response.data.message);
      }

    } catch (err) {
      console.error('Errore dettagliato:', err.response?.data || err);
      if (err.response?.status === 401) {
        setError('Sessione scaduta. Effettua nuovamente il login.');
        // Forza il refresh del token se possibile
        try {
          await refreshToken();
        } catch (refreshError) {
          console.error('Errore nel refresh del token:', refreshError);
        }
      } else {
        setError(err.response?.data?.detail || `Errore durante l'analisi del sito web: ${err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    if (!dataset) return;
    
    try {
      const csvContent = dataset.map(row => 
        `"${row.domanda.replace(/"/g, '""')}","${row.risposta.replace(/"/g, '""')}"`
      ).join('\n');
      
      const blob = new Blob([`domanda,risposta\n${csvContent}`], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `dataset_${businessType}_${new Date().toISOString()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError('Errore durante il download del CSV');
      console.error('Errore download CSV:', error);
    }
  };

  useEffect(() => {
    // Test della connessione API
    const testAPI = async () => {
      try {
        const API_URL = process.env.REACT_APP_API_BASE_URL || 'https://finetuningmax.com/api';
        
        // Test endpoint principale
        const testResponse = await axios.get(`${API_URL}/test`);
        console.log('Main API test successful:', testResponse.data);
        
        // Test endpoint scraper
        const scraperTestResponse = await axios.get(`${API_URL}/scraper/test`);
        console.log('Scraper API test successful:', scraperTestResponse.data);
        
      } catch (err) {
        console.error('API test failed:', err);
      }
    };
    
    testAPI();
  }, []);

  // Validazione delle celle
  const validateCell = (field, value) => {
    if (!value || !value.trim()) {
      return {
        isValid: false,
        message: `Il campo ${field === 'domanda' ? 'domanda' : 'risposta'} non può essere vuoto`
      };
    }

    switch (field) {
      case 'risposta':
        if (value.length > 500) {
          return {
            isValid: false,
            message: "La risposta non può superare i 500 caratteri"
          };
        }
        if (value.length < 10) {
          return {
            isValid: false,
            message: "La risposta deve contenere almeno 10 caratteri"
          };
        }
        break;
        
      case 'domanda':
        if (value.length > 150) {
          return {
            isValid: false,
            message: "La domanda non può superare i 150 caratteri"
          };
        }
        if (value.length < 5) {
          return {
            isValid: false,
            message: "La domanda deve contenere almeno 5 caratteri"
          };
        }
        break;
    }

    return { isValid: true, message: "" };
  };

  // Filtraggio e paginazione dei dati
  const filteredData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    let dataToUse = isEditMode ? editedData : dataset;
    
    if (!dataToUse) return [];

    const dataWithIndices = dataToUse.map((row, originalIndex) => ({
      ...row,
      originalIndex,
      status: validateCell('risposta', row.risposta).isValid ? 'valid' : 'invalid'
    }));
    
    const filteredRows = showErrorsOnly 
      ? dataWithIndices.filter(row => row.status === 'invalid')
      : dataWithIndices;
    
    return filteredRows.slice(startIndex, startIndex + rowsPerPage);
  }, [currentPage, rowsPerPage, dataset, editedData, isEditMode, showErrorsOnly]);

  // Gestione delle azioni sui record
  const handleEditRecord = (index) => {
    const actualIndex = filteredData[index].originalIndex;
    
    setDataset(prevData => {
      const newData = [...prevData];
      newData[actualIndex] = {
        ...newData[actualIndex],
        isEditing: true
      };
      return newData;
    });
    
    setShowActions(null);
  };

  const handleDeleteRecord = (index) => {
    const actualIndex = filteredData[index].originalIndex;
    
    setEditedData(prevData => {
      const currentData = prevData || [...dataset];
      const newData = [...currentData];
      newData.splice(actualIndex, 1);
      return newData;
    });

    setDataset(prevData => {
      const newData = [...prevData];
      newData.splice(actualIndex, 1);
      return newData;
    });
  };

  const handleCellEdit = (index, field, value) => {
    const actualIndex = filteredData[index].originalIndex;
    
    // Aggiorna direttamente il dataset
    setDataset(prevData => {
      const newData = [...prevData];
      newData[actualIndex] = {
        ...newData[actualIndex],
        [field]: value,
        isEditing: true
      };
      return newData;
    });
  };

  const handleSaveEdit = async (index, type, value) => {
    try {
      setIsLoading(true);
      const actualIndex = filteredData[index].originalIndex;
      
      // Validazione prima del salvataggio
      const validation = validateCell(type, value);
      if (!validation.isValid) {
        setError(validation.message);
        return;
      }

      // Salva le modifiche al backend
      const response = await axiosInstance.post('/scraper/save-record', {
        index: actualIndex,
        field: type,
        value: value,
        record: dataset[actualIndex]
      });

      if (response.data.status === 'success') {
        // Aggiorna il dataset locale
        setDataset(prevData => {
          const newData = [...prevData];
          newData[actualIndex] = {
            ...newData[actualIndex],
            [type]: value,
            isEditing: false
          };
          return newData;
        });
        
        setError('');
      }
    } catch (err) {
      setError('Errore durante il salvataggio della modifica');
      console.error('Errore salvataggio:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelEdit = (index) => {
    const actualIndex = filteredData[index].originalIndex;
    
    // Ripristina il valore originale
    setDataset(prevData => {
      const newData = [...prevData];
      newData[actualIndex] = {
        ...newData[actualIndex],
        isEditing: false
      };
      return newData;
    });
  };

  // Gestione della paginazione
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
        Analisi Sito Web
      </h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            URL del sito web
          </label>
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="https://www.example.com"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Tipo di attività
          </label>
          <input
            type="text"
            value={businessType}
            onChange={(e) => setBusinessType(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Es: Ristorante, Hotel, etc."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Numero massimo di pagine
          </label>
          <input
            type="number"
            value={maxPages}
            onChange={(e) => setMaxPages(parseInt(e.target.value))}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            min="1"
            max="50"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Pattern di inclusione (separati da virgola)
          </label>
          <input
            type="text"
            value={includePatterns}
            onChange={(e) => setIncludePatterns(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Es: /blog/*, /products/*, ^/category/[0-9]+"
          />
          <p className="mt-1 text-sm text-gray-500">
            Inserisci percorsi o espressioni regolari per le URL da includere nella scansione
          </p>
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Pattern di esclusione (separati da virgola)
          </label>
          <input
            type="text"
            value={excludePatterns}
            onChange={(e) => setExcludePatterns(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Es: /admin/*, /private/*, .*\\.pdf$"
          />
          <p className="mt-1 text-sm text-gray-500">
            Inserisci percorsi o espressioni regolari per le URL da escludere dalla scansione
          </p>
        </div>

        {error && (
          <div className="text-red-600 dark:text-red-400">
            {error}
          </div>
        )}

        <button
          onClick={handleAnalyze}
          disabled={isLoading || !url || !businessType}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isLoading ? (
            <div className="flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Analisi in corso...
            </div>
          ) : (
            'Analizza Sito'
          )}
        </button>

        {/* Tabella dei risultati */}
        {dataset && dataset.length > 0 && (
          <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
                Dataset Estratto ({dataset.length} record)
              </h3>
              <div className="flex gap-2">
                <button
                  onClick={handleDownloadCSV}
                  className="px-4 py-2 text-sm bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  Scarica CSV
                </button>
              </div>
            </div>

            {/* Tabella con paginazione */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Domanda
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Risposta
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Azioni
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredData.map((row, index) => {
                    const validation = {
                      domanda: validateCell('domanda', row.domanda),
                      risposta: validateCell('risposta', row.risposta)
                    };

                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-normal">
                          {row.isEditing ? (
                            <div className="relative">
                              <input
                                type="text"
                                value={row.domanda}
                                onChange={(e) => handleCellEdit(index, 'domanda', e.target.value)}
                                className={`w-full p-2 rounded border ${
                                  !validation.domanda.isValid ? 'border-red-500' : 'border-gray-300'
                                } dark:bg-gray-700 dark:text-white`}
                              />
                              <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                                {row.domanda.length} caratteri
                              </div>
                              <div className="mt-2 flex justify-end space-x-2">
                                <button
                                  onClick={() => handleCancelEdit(index)}
                                  className="px-3 py-1 rounded-md text-sm bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600"
                                >
                                  Annulla
                                </button>
                                <button
                                  onClick={() => handleSaveEdit(index, 'domanda', row.domanda)}
                                  className="px-3 py-1 rounded-md text-sm bg-green-500 text-white hover:bg-green-600"
                                >
                                  Salva
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={`text-sm ${!validation.domanda.isValid ? 'text-red-500' : 'text-gray-900 dark:text-gray-100'}`}>
                              {row.domanda}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-normal">
                          {row.isEditing ? (
                            <div className="relative">
                              <textarea
                                value={row.risposta}
                                onChange={(e) => handleCellEdit(index, 'risposta', e.target.value)}
                                className={`w-full p-2 rounded border ${
                                  !validation.risposta.isValid ? 'border-red-500' : 'border-gray-300'
                                } dark:bg-gray-700 dark:text-white`}
                                rows={3}
                              />
                              <div className="absolute bottom-2 right-2 text-xs text-gray-400">
                                {row.risposta.length} caratteri
                              </div>
                              <div className="mt-2 flex justify-end space-x-2">
                                <button
                                  onClick={() => handleCancelEdit(index)}
                                  className="px-3 py-1 rounded-md text-sm bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600"
                                >
                                  Annulla
                                </button>
                                <button
                                  onClick={() => handleSaveEdit(index, 'risposta', row.risposta)}
                                  className="px-3 py-1 rounded-md text-sm bg-green-500 text-white hover:bg-green-600"
                                >
                                  Salva
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className={`text-sm ${!validation.risposta.isValid ? 'text-red-500' : 'text-gray-900 dark:text-gray-100'}`}>
                              {row.risposta}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="flex gap-2">
                            <button
                              onClick={() => handleEditRecord(index)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <PencilIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDeleteRecord(index)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Paginazione migliorata */}
            <div className="mt-4 flex justify-between items-center">
              <div className="flex items-center gap-2">
                <select
                  value={rowsPerPage}
                  onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                  className="border border-gray-300 dark:border-gray-600 rounded px-2 py-1 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                >
                  {[10, 25, 50].map((size) => (
                    <option key={size} value={size}>
                      {size} per pagina
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                    ${currentPage === 1 
                      ? 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-500 cursor-not-allowed'
                      : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600'
                    }`}
                >
                  Precedente
                </button>
                <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                  Pagina {currentPage} di {Math.ceil((dataset?.length || 0) / rowsPerPage)}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage >= Math.ceil((dataset?.length || 0) / rowsPerPage)}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                    ${currentPage >= Math.ceil((dataset?.length || 0) / rowsPerPage)
                      ? 'bg-gray-100 dark:bg-gray-800 text-gray-400 dark:text-gray-500 cursor-not-allowed'
                      : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600'
                    }`}
                >
                  Successiva
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteAnalyzer; 